import centreAcknowledgements from '../../content/centres-acknowledgement/index';
export const NATIONAL_ACKNOWLEDGEMENT_AU = 'We acknowledge the Traditional Owners and communities of the lands on which our business operates. We pay our respect to Aboriginal and Torres Strait Islander cultures and to their Elders past and present.';
export const NATIONAL_ACKNOWLEDGEMENT_NZ = 'We recognise the unique role of Māori as Tangata Whenua of Aotearoa/New Zealand.';
export const getCentreAcknowledgement = (country, slug) => {
  if (country === 'Australia') {
    const centre = centreAcknowledgements.find(centre => centre.slug === slug);
    return (centre === null || centre === void 0 ? void 0 : centre.content) || NATIONAL_ACKNOWLEDGEMENT_AU;
  }
  return NATIONAL_ACKNOWLEDGEMENT_NZ;
};
export const getCentreAcknowledgementCountry = slug => {
  const centre = centreAcknowledgements.find(centre => centre.slug === slug);
  return centre === null || centre === void 0 ? void 0 : centre.country;
};