// common `sizes`
export const IMAGE_SIZES_THUMBNAIL = 96;
export const IMAGE_SIZES_PRODUCT_CARD = ['(max-width: 447px) 50vw', '(max-width: 703px) 30vw', '(max-width: 927px) calc(25vw - 50px)', '200px'].join(', ');
export const IMAGE_SIZES_FULL_WIDTH = {
  md: '100vw',
  lg: 1072
};
export const IMAGE_SIZES_HERO = {
  md: '100vw',
  lg: 320,
  xl: 384
};
export const IMAGE_SIZES_HERO_PORTRAIT = {
  md: '100vw',
  lg: 215,
  xl: 248
};
export const IMAGE_SIZES_TILE_PRIMARY = {
  sm: '100vw',
  md: 350,
  xl: 400
};
export const IMAGE_SIZES_TILE_SECONDARY = {
  sm: '50vw',
  md: 320,
  xl: 350
};
export const IMAGE_SIZES_DECK_PRIMARY = {
  sm: '100vww',
  md: 300,
  lg: 384,
  xl: 430
};
export const IMAGE_SIZES_DECK_SECONDARY = 200;
export const IMAGE_SIZES_SLIM_CARD_HERO = '(max-width 703px) 116px, 196px';
export const IMAGE_SIZES_COLOR_CARD_HERO = {
  sm: '100vw',
  md: 424,
  xl: 288
};
export const IMAGE_SIZES_DECK_REEL = {
  md: '80vw',
  lg: '30vw',
  xl: 350
};
export const IMAGE_SIZES_GRID_PRIMARY = count => ["(max-width: 447px) ".concat(Math.ceil(100 / Math.min(2, count)), "vw"), "(max-width: 607px) ".concat(Math.ceil(100 / Math.min(3, count)), "vw"), "(max-width: 751px) ".concat(Math.ceil(100 / Math.min(4, count)), "vw"), "(max-width: 775px) ".concat(Math.ceil(100 / Math.min(5, count)), "vw"), "(max-width: 1152px) ".concat(Math.ceil(100 / Math.min(6, count)), "vw"), "".concat(1080 / Math.min(6, count), "px")].join(', ');
export const IMAGE_SIZES_GRID_SECONDARY = 56;
export const IMAGE_SIZES_BROWSE_GRID = {
  sm: '50vw',
  md: '30vw',
  lg: 151,
  xl: 184
};
export const IMAGE_SIZES_BROWSE_LIST = 128;
export const IMAGE_SIZES_GALLERY_MAIN = '(max-width: 927px) 100vw, 485px';
export const IMAGE_SIZES_GALLERY_THUMBNAIL = '(max-width: 927px) 15vw, 100px';
export const IMAGE_SIZES_HALF_HALF = '(max-width: 927px) 100vw, (max-width: 1072px) 50vw, 468px';