import { standardRatios } from '@hub/frame';
import { clamp } from 'lodash';
const MAX_IMAGE_WIDTH = 1200;
const MIN_IMAGE_WIDTH = 100;
const URL_PREFIX = 'https://images.scentregroup.io/image/fetch/';
const RESIZE_MODES = {
  'crop-face': 'c_fill,q_auto,g_faces:auto',
  'crop-center': 'c_fill,q_auto,g_center',
  pad: 'c_pad,b_auto'
};
const WIDTH_TOLERANCE = 1.2;

/**
 * Specifications here {@link https://cloudinary.com/documentation/transformation_reference#f_format}
 */

function getWidth(maxWidth) {
  const maxResizeWidth = false ? maxWidth !== null && maxWidth !== void 0 ? maxWidth : MAX_IMAGE_WIDTH : clamp(Math.floor(window.innerWidth * WIDTH_TOLERANCE), MIN_IMAGE_WIDTH, maxWidth !== null && maxWidth !== void 0 ? maxWidth : MAX_IMAGE_WIDTH);
  return Math.max(maxResizeWidth, MIN_IMAGE_WIDTH);
}
function getHeight(width, currentRatio) {
  const imageRatio = typeof currentRatio === 'string' ? standardRatios[currentRatio] : currentRatio !== null && currentRatio !== void 0 ? currentRatio : 1;
  return Math.floor(width / imageRatio);
}
function extractPreferredImageUrl(preferredImage) {
  const baseUrl = typeof preferredImage === 'string' ? preferredImage : preferredImage.url;
  return baseUrl.startsWith('//') ? "https:".concat(baseUrl) : baseUrl;
}
export default function makeImageUrl(preferredImage, currentRatio, currentResizeMode, maxWidth, dpr,
// If `none`, ignore format parameter. If undefined/null, default to `f_auto`
imageFormat) {
  const computedMaxWidth = getWidth(maxWidth);
  const computedMaxHeight = getHeight(computedMaxWidth, currentRatio);
  const cloudinaryResizeMode = RESIZE_MODES[currentResizeMode !== null && currentResizeMode !== void 0 ? currentResizeMode : 'crop-face'];
  const cropOptions = [cloudinaryResizeMode, "w_".concat(computedMaxWidth, ",h_").concat(computedMaxHeight)];
  if (imageFormat !== 'none') {
    cropOptions.push(imageFormat !== null && imageFormat !== void 0 ? imageFormat : 'f_auto');
  }
  cropOptions.push("dpr_".concat(dpr !== null && dpr !== void 0 ? dpr : 1));
  const preferredImageUrl = extractPreferredImageUrl(preferredImage);
  return "".concat(URL_PREFIX).concat(cropOptions.join(','), "/").concat(preferredImageUrl);
}
export function makeUnscaledImageUrl(url) {
  return "".concat(URL_PREFIX, "/").concat(url);
}
export function makeSchemaImageUrls(baseUrl) {
  return ['16/9', '4/3', '1'].map(ratio => makeImageUrl(baseUrl, ratio));
}