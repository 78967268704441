import analyticsUtils from '../../helpers/analytics-utils';
import getDisplayNameForHit from './get-display-name-for-hit';
export const makeOnResultClick = queryText => (heading, hit) => {
  analyticsUtils.DEPRECATED_gaTagTracking({
    event: analyticsUtils.events.SEARCH,
    eventCategory: analyticsUtils.categories.GLOBAL_SEARCH,
    eventAction: analyticsUtils.actions.SEARCH_RESULT_ClICKED,
    eventLabel: queryText,
    globalResultClicked: "".concat(heading, "/").concat(getDisplayNameForHit(hit))
  });
};
export const logOnClearSearch = query => {
  analyticsUtils.DEPRECATED_gaTagTracking({
    event: analyticsUtils.events.SEARCH,
    eventCategory: analyticsUtils.categories.GLOBAL_SEARCH,
    eventAction: analyticsUtils.actions.CLICK_TO_CLEAR_SEARCH_KEYWORD,
    eventLabel: query
  });
};
export const logHideSearch = query => analyticsUtils.DEPRECATED_gaTagTracking({
  event: analyticsUtils.events.SEARCH,
  eventCategory: analyticsUtils.categories.GLOBAL_SEARCH,
  eventAction: analyticsUtils.actions.CLICK_TO_CLOSE_SEARCH,
  eventLabel: query
});
export const logShowSearch = () => analyticsUtils.DEPRECATED_gaTagTracking({
  event: analyticsUtils.events.SEARCH,
  eventCategory: analyticsUtils.categories.GLOBAL_SEARCH,
  eventAction: analyticsUtils.actions.CLICK_TO_START_SEARCH
});
export const logOnResultsChange = (query, numHits) => analyticsUtils.DEPRECATED_gaTagTracking({
  event: analyticsUtils.events.SEARCH,
  eventCategory: analyticsUtils.categories.GLOBAL_SEARCH,
  eventAction: analyticsUtils.actions.SEARCH_SHOWN,
  eventLabel: query,
  noReturnedResults: numHits
});
export const logSeeAllProducts = () => analyticsUtils.DEPRECATED_gaTagTracking({
  event: analyticsUtils.events.SEARCH,
  eventCategory: analyticsUtils.categories.GLOBAL_SEARCH,
  eventAction: analyticsUtils.actions.SEARCH_RESULT_ClICKED,
  eventLabel: analyticsUtils.labels.SEE_ALL_PRODUCTS
});