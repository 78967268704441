const FRAC_DIGITS = 2;
export function displayStringForPriceRange(range) {
  if (!(range !== null && range !== void 0 && range.min)) {
    return '';
  }
  if (range.min === range.max) {
    return "$".concat(range.min.toFixed(FRAC_DIGITS));
  }
  return "$".concat(range.min.toFixed(FRAC_DIGITS), " \u2013 $").concat(range.max.toFixed(FRAC_DIGITS));
}
export function displayStringForPriceRangeWithoutCurrency(range) {
  if (!(range !== null && range !== void 0 && range.min)) {
    return '';
  }
  if (range.min === range.max) {
    return "".concat(range.min.toFixed(FRAC_DIGITS));
  }
  return "".concat(range.min.toFixed(FRAC_DIGITS), " \u2013 ").concat(range.max.toFixed(FRAC_DIGITS));
}
export function displayMinimumPriceWithoutCurrency(range) {
  if (!(range !== null && range !== void 0 && range.min)) {
    return '';
  }
  return "".concat(range.min.toFixed(FRAC_DIGITS));
}