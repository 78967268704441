import { Segment } from './types';
export function getFieldForSegment(segment) {
  if (segment === Segment.westfieldProducts) {
    return 'name';
  } else if (segment === Segment.sites) {
    return 'text';
  } else if (segment === Segment.stores) {
    return 'titleWithOptionalStatus';
  } else {
    return 'title';
  }
}
export function getSupplementaryTextForHit(hit) {
  if (hit.segment === 'stores' || hit.segment === 'otherStores') {
    return " ".concat(hit.centreName);
  } else if (hit.segment === 'nationalStorefronts') {
    const suffix = '';
    return " All stores".concat(suffix);
  }
  return '';
}