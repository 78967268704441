/*
Common core of algolia functionality used by
both the use-algolia Hook, and the Next JS
GetServerSideProps for server rendering
*/

import algoliasearch from 'algoliasearch';
import { getNowInCentreInTimeFormat } from '../../helpers/date-helpers';
import { Segment } from './types';
import querySegments from './query-segments';
import { makeOnResultClick } from './analytics';
import groupAndRenderSegments from './group-and-render-segments';
const getDefaultNationalTimezone = country => {
  if (country === 'New Zealand') {
    return 'Pacific/Auckland';
  } else {
    return 'Australia/Sydney';
  }
};
export const makeOffsetlessTimeGetters = (country, centre) => ({
  getOffsetlessTimeInDefaultTz: getNowInCentreInTimeFormat(getDefaultNationalTimezone(country)),
  getOffsetlessTimeInCentre: centre && getNowInCentreInTimeFormat(centre.timeZone)
});
export function makeClients(algoliaApiKeys, extraConfig) {
  const {
    contentApplicationId,
    contentApiKey
  } = algoliaApiKeys;
  if (!contentApplicationId || !contentApiKey) {
    return undefined;
  }
  return {
    content: algoliasearch(contentApplicationId, contentApiKey, extraConfig)
  };
}
const toExclude = [
// search suggestions are only relevant to live search:
Segment.searchSuggestions];
export const doQueryAlgolia = _ref => {
  let {
    query,
    clients,
    context
  } = _ref;
  const segments = Object.values(Segment).filter(s => !toExclude.includes(s));
  return clients ? querySegments(query, clients, context, segments) : Promise.reject(new Error('Algolia clients not initalised'));
};
export const searchSuggestionsContextFromQueryContext = context => {
  const countryCode = context.country === 'New Zealand' ? 'NZ' : 'AU';
  const suffix = context.specialSearchKind === 'stores' ? 'stores search' : context.centre ? 'centre defaults' : 'national search';
  return "".concat(countryCode, " ").concat(suffix);
};
export function processResults(query, context, segmentHits) {
  const onResultClickHeading = makeOnResultClick(query);
  const results = groupAndRenderSegments({
    segmentResults: segmentHits,
    context,
    onResultClickHeading
  });
  const showNoResults = query.length > 0 && results.length === 0;
  return {
    showNoResults,
    results
  };
}