import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useMemo, useCallback } from 'react';
import Fuse from 'fuse.js';
const defaultCompare = (centreA, centreB) => {
  const stateCompare = centreA.state.localeCompare(centreB.state);
  if (stateCompare) {
    return stateCompare;
  } else {
    return centreA.title.localeCompare(centreB.title);
  }
};
export const useCentreFilter = centres => {
  const sortedCentresWithSearchTitle = useMemo(() => centres.map(centre => {
    var _centre$title$split, _centre$title, _centre$title$split2;
    return _objectSpread(_objectSpread({}, centre), {}, {
      searchTitle: (_centre$title$split = centre === null || centre === void 0 || (_centre$title = centre.title) === null || _centre$title === void 0 || (_centre$title$split2 = _centre$title.split) === null || _centre$title$split2 === void 0 ? void 0 : _centre$title$split2.call(_centre$title, ' ')) !== null && _centre$title$split !== void 0 ? _centre$title$split : []
    });
  }).sort(defaultCompare), [centres]);
  const fuse = useMemo(() => new Fuse(sortedCentresWithSearchTitle, {
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    keys: [{
      name: 'searchTitle',
      weight: 0.8
    }, {
      name: 'state',
      weight: 0.1
    },
    // We look at title as well as the split up version
    // searchTitle because otherwise queries with spaces
    // ("Mt Druitt", "Bondi Junction") return no results
    {
      name: 'title',
      weight: 0.1
    }]
  }), [sortedCentresWithSearchTitle]);
  return useCallback(query => query ? fuse.search(query) : sortedCentresWithSearchTitle, [sortedCentresWithSearchTitle, fuse]);
};