import React from 'react';
export const useLayoutLogo = (centre, variant) => {
  return React.useMemo(() => {
    const logoLink = (() => {
      if (centre) {
        return "/".concat(centre.slug);
      }
      if (variant === 'gift-cards') {
        return '/gift-cards';
      }
      return '/';
    })();
    const logoLabel = (() => {
      if (centre) {
        return "Explore Westfield ".concat(centre.title);
      }
      if (variant === 'gift-cards') {
        return 'Westfield Gift Cards';
      }
      return 'Explore Westfield';
    })();
    const logoSlug = (() => {
      if (centre) {
        return centre.slug;
      }
      if (variant == 'gift-cards') {
        return 'gift-cards';
      }
      return undefined;
    })();
    return {
      logoLink,
      logoLabel,
      logoSlug
    };
  }, [centre, variant]);
};