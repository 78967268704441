import React from 'react'
import {
  NavigationContextProvider,
  useNavigationContext,
} from './navigation-context'
import { ListItem } from '@hub/list'

export const NavigationMenu: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const buttonRef = React.useRef<HTMLElement>(null)
  const { isOpen, onOpen, onClose, onMouseEnter, onClickButton } =
    useNavigationContext()
  const items = React.useMemo(() => {
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) {
        return child
      }
      return (
        <NavigationContextProvider
          value={{
            buttonRef,
            isMenu: true,
            isOpen,
            onClick(event) {
              onClickButton?.(event, child.props.data)
              if (isOpen) {
                onClose?.()
              } else {
                onOpen?.()
              }
            },
            onMouseEnter(event) {
              onMouseEnter?.(event)
            },
          }}
        >
          {child}
        </NavigationContextProvider>
      )
    })
  }, [children, isOpen, onClickButton, onClose, onMouseEnter, onOpen])

  return <ListItem>{items}</ListItem>
}
