import { range } from 'lodash';
import moment from 'moment-timezone';
import { formatHumanDate } from './date-format-helpers';
import { timezonelessDate } from './time-ranges';

// Originally this project started with Luxon but due to
// difficulties with Luxon and timezones in some browsers
// it was decided to convert this to moment. Because of the
// large number of date calculations in the codebase it was
// decided to make minimal changes to the existing API and
// instead monkey patch moment.

// Only changes to format strings were made. Overtime this can
// be moved more towards the moment API.
const OFFSET_TO_MILLISECONDS = 60000;
export const getAMPM = date => moment(date).format('h[.]mma');
export const DateTime = {
  fromISO: (dateString, opts) => {
    if (opts && opts.timeZone) {
      return moment(moment.tz(dateString, opts.timeZone), opts.format);
    }
    return moment(dateString, opts === null || opts === void 0 ? void 0 : opts.format);
  },
  local: opts => {
    if (opts && opts.timeZone) {
      return moment.tz(moment(), opts.timeZone);
    }
    if (opts && opts.format) {
      return moment(moment(), opts.format);
    }
    return moment();
  },
  moment
};
export const getToday = centreTime => DateTime.local({
  timeZone: centreTime
}).startOf('day');
export const localISODate = timeZone => DateTime.local({
  timeZone
}).format('YYYY-MM-DD');
/**
 * Get now in centre, drop offset and return a function to get it
 * @param {string} centreTimeZone - The centre timezone.
 * @returns {Function} func - an exposure function to call and get the TIME
 */
export const getNowInCentreInTimeFormat = centreTimeZone => {
  let currentTime = null;
  return function () {
    if (currentTime) {
      return currentTime;
    }
    // get the time in time zone
    const momentNow = moment.tz(new Date(), centreTimeZone);
    // strip out the offset by adding utcOffset and time it by milliseconds to get the correct time value
    const timeInAlgolia = new Date(momentNow.valueOf() + momentNow.utcOffset() * OFFSET_TO_MILLISECONDS).getTime();
    currentTime = Math.floor(timeInAlgolia);
    return currentTime;
  };
};
export const getNowInCentreInDateFormat = centreTimeZone => timezonelessDate(DateTime.local({
  timeZone: centreTimeZone
}).format());
export const isToday = (dt, timeZone) => {
  const today = DateTime.local({
    timeZone
  });
  return timeZone ? today.isSame(dt, 'day') : dt.isSame(today, 'day');
};
export const isTomorrow = (dt, timeZone) => {
  const tomorrow = DateTime.local({
    timeZone
  }).add(1, 'day');
  return timeZone ? tomorrow.isSame(dt, 'day') : dt.isSame(tomorrow, 'day');
};
export const isSameOrAfterToday = (dateStr, timeZone) => DateTime.fromISO(dateStr, {
  timeZone
}).isSameOrAfter(DateTime.local({
  timeZone
}));
export const isBeforeNow = (dateStr, timeZone) => DateTime.fromISO(dateStr, {
  timeZone
}).isBefore(DateTime.local({
  timeZone
}));
export const getWeekAhead = (centreTime, daysAhead) => [{
  displayDate: 'All',
  date: 'All'
}, ...range(daysAhead).map(i => {
  const DAY = getToday(centreTime).add(i, 'days');
  return {
    displayDate: formatHumanDate(DAY),
    date: DAY.format('YYYY-MM-DD')
  };
})];