import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import querystring from 'querystring';
export const transformParsedUrlQuery = parsedQuery => Object.entries(parsedQuery).flatMap(_ref => {
  let [key, value] = _ref;
  if (Array.isArray(value)) {
    return value.map(v => [key, v]);
  }
  return [[key, value !== null && value !== void 0 ? value : '']];
});
const routeMatcher = new RegExp('(\\[\\[\\.\\.\\.(?<optionalCatchAll>\\w+)\\]\\])|(\\[\\.\\.\\.(?<catchAll>\\w+)\\])|(\\[(?<dynamic>\\w+)\\])', 'g');
/**
 * Get a URL path with query/search parameters from a
 * router object (eg. from Next.js useRouter).
 *
 * Will replace dynamic route parameters and include the URL search params.
 *
 * Optional `overrides` parameter allows rewriting the dynamic
 * route parameters and search parameters.
 *
 * Set an override value to `undefined` to remove a search parameter.
 *
 * Returns a string to use as a link to the route.
 */
export function getAbsolutePathFromRouter(router, queryOverrides, queryExtra) {
  const {
    route,
    basePath
  } = router;
  const query = _objectSpread(_objectSpread({}, router.query), queryExtra);
  const override = queryOverrides ? rewriteParsedUrlQuery(query, queryOverrides) : query;
  const e = encodeURIComponent;
  const catchAllReplacer = k => {
    const a = override && override[k];
    if (Array.isArray(a)) {
      return a.map(e).join('/');
    }
    if (typeof a === 'string') {
      return e(a);
    }
    return '';
  };
  const dynamicReplacer = k => override && override[k] && e(String(override[k])) || '';
  const matches = Array.from(route.matchAll(routeMatcher));
  const path = matches.reduce((s, m) => s.replace(m[0], m.groups && (catchAllReplacer(m.groups.optionalCatchAll) || catchAllReplacer(m.groups.catchAll) || dynamicReplacer(m.groups.dynamic)) || ''), route).replace(/\/$/, '');
  const entries = transformParsedUrlQuery(override);
  const searchParams = new URLSearchParams(entries);
  for (const {
    groups
  } of matches) {
    if (!groups) {
      continue;
    }
    searchParams.delete(groups.optionalCatchAll || groups.catchAll || groups.dynamic);
  }
  if (Array.from(searchParams).length > 0) {
    return "".concat(basePath).concat(path, "?").concat(searchParams.toString());
  }
  return "".concat(basePath).concat(path);
}
export function rewriteParsedUrlQuery(query, params) {
  const keys = Object.keys(params);
  const entries = transformParsedUrlQuery(query).reduce((entries, _ref2) => {
    let [k, v] = _ref2;
    if (keys.includes(k) && entries.find(_ref3 => {
      let [key] = _ref3;
      return k === key;
    })) {
      return entries;
    }
    const value = keys.includes(k) ? params[k] : v;
    if (value === undefined) {
      return entries;
    }
    if (Array.isArray(value)) {
      return [...entries, ...value.map(val => [k, val])];
    }
    return [...entries, [k, value]];
  }, []);
  return querystring.parse(new URLSearchParams(entries).toString());
}