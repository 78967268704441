import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import { useRef, useState, useEffect } from 'react';
import useOnce from '@scentregroup/shared/hooks/use-once';
export class AutocompleteRefs {
  constructor(_ref) {
    let {
      inputRef,
      formRef,
      panelRef,
      itemsRef,
      seeAllRef
    } = _ref;
    _defineProperty(this, "inputRef", void 0);
    _defineProperty(this, "formRef", void 0);
    _defineProperty(this, "panelRef", void 0);
    _defineProperty(this, "itemsRef", void 0);
    _defineProperty(this, "seeAllRef", void 0);
    this.inputRef = inputRef;
    this.formRef = formRef;
    this.panelRef = panelRef;
    this.itemsRef = itemsRef;
    this.seeAllRef = seeAllRef;
  }

  // This return type looks wrong but it seems to be what
  // Autocomplete wants
  getElems() {
    return {
      inputElement: this.inputRef.current,
      formElement: this.formRef.current,
      panelElement: this.panelRef.current,
      seeAllElement: this.seeAllRef.current,
      itemElements: this.itemsRef.current
    };
  }
  getElemAtCursor(cursor) {
    const {
      inputElement,
      seeAllElement,
      itemElements
    } = this.getElems();
    if (itemElements === null) {
      return null;
    } else if (cursor === -1) {
      return inputElement;
    } else if (cursor === itemElements.length) {
      return seeAllElement;
    } else {
      return itemElements[cursor];
    }
  }
}
export default function useRefsAndCursor(query, collections) {
  const {
    0: cursor,
    1: setCursor
  } = useState(-1);
  const inputRef = useRef(null);
  const formRef = useRef(null);
  const panelRef = useRef(null);
  const seeAllRef = useRef(null);
  const itemsRef = useRef([]);
  const autocompleteRefs = useOnce(() => new AutocompleteRefs({
    inputRef,
    formRef,
    panelRef,
    itemsRef,
    seeAllRef
  }));
  const numItems = (collections !== null && collections !== void 0 ? collections : []).flatMap(collection => collection.items).length;
  // Resize the ref list to the current collection:
  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, numItems);
  }, [query, numItems]);
  const moveDown = () => {
    if (cursor < itemsRef.current.length) {
      var _autocompleteRefs$get;
      (_autocompleteRefs$get = autocompleteRefs.getElemAtCursor(cursor + 1)) === null || _autocompleteRefs$get === void 0 || _autocompleteRefs$get.focus();
      setCursor(c => c + 1);
    }
  };
  const moveUp = () => {
    if (cursor > -1) {
      var _autocompleteRefs$get2;
      (_autocompleteRefs$get2 = autocompleteRefs.getElemAtCursor(cursor - 1)) === null || _autocompleteRefs$get2 === void 0 || _autocompleteRefs$get2.focus();
      setCursor(c => c - 1);
    }
  };
  const resetCursor = () => {
    setCursor(-1);
  };
  return {
    autocompleteRefs,
    moveDown,
    moveUp,
    resetCursor
  };
}