import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React from 'react';
import { useGetSources, useAlgoliaClients, makeOffsetlessTimeGetters, useReshape } from '../../components/search';
import { getAlgoliaApiKeys } from '../../config';
import { useLocalUser } from '../../hooks/use-local-user';
import { MagicLinkValue, useMagicLink } from '../magic-link';
export function useLayoutSearch(country, centre) {
  const user = useLocalUser();
  const [isOpenSearchModal, setIsOpenSearchModal] = React.useState(false);
  const [magicLink, setMagicLink] = useMagicLink();
  React.useEffect(() => {
    if (magicLink === MagicLinkValue.stores || magicLink === MagicLinkValue.centres) {
      setIsOpenSearchModal(true);
    }
  }, [magicLink]);
  React.useEffect(() => {
    if (!isOpenSearchModal) {
      setMagicLink();
    }
  }, [isOpenSearchModal, setMagicLink]);
  const specialSearchKind = React.useMemo(() => {
    if (magicLink === MagicLinkValue.centres) {
      return 'centres';
    }
    if (magicLink === MagicLinkValue.stores) {
      return 'stores';
    }
    return undefined;
  }, [magicLink]);
  const clients = useAlgoliaClients(getAlgoliaApiKeys());
  const context = {
    centre,
    country,
    specialSearchKind,
    user
  };
  const timeGetters = makeOffsetlessTimeGetters(country, centre);
  const searchContext = _objectSpread(_objectSpread(_objectSpread({}, context), timeGetters), {}, {
    query: ''
  });
  const reshape = useReshape(searchContext);
  const getSources = useGetSources(clients, searchContext);
  const onSubmit = query => {
    const centrePath = centre ? "/".concat(centre.slug) : '';
    const q = encodeURIComponent(query || '');
    setIsOpenSearchModal(false);

    // TODO when all clients are under one routing
    // use history.push from the router
    window.location.assign("".concat(centrePath, "/search?q=").concat(q));
  };
  return [isOpenSearchModal, setIsOpenSearchModal, specialSearchKind, getSources, onSubmit, reshape];
}