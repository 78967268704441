import React from 'react';
import { isDefinedAndNotNull } from '../../lib';
export const useLayoutNavigation = (giftCardsData, primaryLinks, secondaryLinks) => {
  const [isOpenPrimaryMenu, setIsOpenPrimaryMenu] = React.useState(false);
  const [isOpenSecondaryMenu, setIsOpenSecondaryMenu] = React.useState(false);
  const giftCards = React.useMemo(() => {
    return giftCardsData === null || giftCardsData === void 0 ? void 0 : giftCardsData.filter(isDefinedAndNotNull);
  }, [giftCardsData]);
  return {
    isOpenPrimaryMenu,
    setIsOpenPrimaryMenu,
    isOpenSecondaryMenu,
    setIsOpenSecondaryMenu,
    giftCards,
    primaryMenuItems: primaryLinks,
    secondaryMenuItems: secondaryLinks
  };
};