import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import getRenderForSegment from './get-render-for-segment';
import groupSegmentsByHeadings from './group-segments-by-heading';
import { makeHeadingInfo } from './headings';
export function groupSegments(args) {
  const {
    headingsToDisplay,
    getHeadingForSegment
  } = makeHeadingInfo(args.context);
  return groupSegmentsByHeadings(_objectSpread(_objectSpread({}, args), {}, {
    headingsToDisplay,
    getHeadingForSegment
  }));
}
export default function groupAndRenderSegments(args) {
  const grouped = groupSegments(args);
  return grouped.map(_ref => {
    let {
      heading,
      groupedHits,
      indexName,
      queryId
    } = _ref;
    return {
      queryId,
      indexName,
      heading,
      hits: groupedHits.map((_ref2, index) => {
        let {
          hit,
          context,
          onResultClick
        } = _ref2;
        return getRenderForSegment({
          hit,
          context: _objectSpread(_objectSpread({}, context), {}, {
            queryID: queryId,
            indexName
          }),
          onResultClick,
          index
        });
      })
    };
  });
}