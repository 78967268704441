import qs from 'query-string';
import canonical from './canonical';
const shouldDefaultCanonical = params => {
  const paramCountWithPaging = 3;
  const paramCountNoPaging = 2;
  const numParams = Object.keys(params.query).length;
  const hasIgnoredParams = params.query.minPrice || params.query.maxPrice || params.query.search_query;
  const hasTooManyParams = params.query.page && numParams > paramCountWithPaging || !params.query.page && numParams > paramCountNoPaging;
  const hasInvalidCombo = params.query.brand && params.query.retailer;
  return hasIgnoredParams || hasTooManyParams || hasInvalidCombo;
};
const productsCanonical = params => {
  const paramsOrder = ['colour', 'retailer', 'brand', 'on_sale', 'page'];
  const newParams = JSON.parse(JSON.stringify(params));
  if (newParams.query.page && (newParams.query.page === '1' || newParams.query.page === '0')) {
    delete newParams.query.page;
  }
  const search = qs.stringify(newParams.query, {
    sort: (m, n) => paramsOrder.indexOf(m) >= paramsOrder.indexOf(n)
  });
  return search ? "".concat(params.url, "?").concat(search) : params.url;
};
const products = href => {
  const params = qs.parseUrl(href);
  return shouldDefaultCanonical(params) ? canonical(href) : productsCanonical(params);
};
export default products;