import { HEADINGS } from '../../group-and-render-segments/headings';
export function substituteSources(sources) {
  const {
    topStores,
    otherStores
  } = HEADINGS;
  const storeSources = [topStores, otherStores];
  const unsubstitutedSources = sources.filter(source => !storeSources.includes(source.sourceId));
  const baseTopStores = sources.find(source => source.sourceId === topStores);
  const baseOtherStores = sources.find(source => source.sourceId === otherStores);
  if (baseTopStores && baseTopStores.getItems().length > 0) {
    return [...unsubstitutedSources, baseTopStores];
  } else if (baseOtherStores) {
    return [...unsubstitutedSources, baseOtherStores];
  } else {
    return unsubstitutedSources;
  }
}