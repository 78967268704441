const centreSlug = context => {
  var _context$centre$slug, _context$centre;
  return (_context$centre$slug = (_context$centre = context.centre) === null || _context$centre === void 0 ? void 0 : _context$centre.slug) !== null && _context$centre$slug !== void 0 ? _context$centre$slug : '';
};
const getUrlForNationalStorefront = hit => "/store/".concat(hit.retailerId, "/").concat(hit.slug);
const getUrlForStore = (hit, context) => "/".concat(centreSlug(context), "/store/").concat(hit.objectID, "/").concat(hit.slug);
const getUrlForSite = (hit, context) => (hit.href || '').replace('CENTRE', centreSlug(context));
const getUrlForService = (hit, context) => "/".concat(centreSlug(context), "/service/").concat(hit.objectID, "/").concat(hit.slug);
const getUrlForLocalOffer = (hit, context) => "/".concat(centreSlug(context), "/offer/").concat(hit.objectID, "/").concat(hit.slug);
const getUrlForNationallOffer = (hit, _context) => "/offer/".concat(hit.objectID, "/").concat(hit.slug);
const getUrlForEvent = (hit, context) => "/".concat(centreSlug(context), "/event/").concat(hit.objectID, "/").concat(hit.slug);
const getUrlForLocalNews = (hit, context) => "/".concat(centreSlug(context), "/news/").concat(hit.objectID, "/").concat(hit.slug);
const getUrlForNationalNews = (hit, _context) => "/news/".concat(hit.objectID, "/").concat(hit.slug);
const getUrlForLocalStory = (hit, context) => "/".concat(centreSlug(context), "/story/").concat(hit.objectID, "/").concat(hit.slug);
const getUrlForNationalStory = (hit, _context) => "/story/".concat(hit.objectID, "/").concat(hit.slug);
const getUrlForCentre = (hit, _context) => hit.href;
const getUrlForOnlineService = (hit, _context) => hit.href;
const getUrlForOtherStore = (hit, _context) => "/".concat(hit.centreSlug, "/store/").concat(hit.objectID, "/").concat(hit.slug);
const getUrlForWestfieldProduct = (hit, _context) => "".concat(hit.href);
const getUrlForSearchSuggestion = (hit, context) => {
  var _hit$url;
  return ((_hit$url = hit.url) !== null && _hit$url !== void 0 ? _hit$url : '').replace('CENTRE', centreSlug(context));
};
export default function getUrlForHit(hit, context) {
  switch (hit.segment) {
    case 'nationalStorefronts':
      return getUrlForNationalStorefront(hit, context);
    case 'stores':
      return getUrlForStore(hit, context);
    case 'sites':
      return getUrlForSite(hit, context);
    case 'services':
      return getUrlForService(hit, context);
    case 'localOffers':
      return getUrlForLocalOffer(hit, context);
    case 'nationalOffers':
      return getUrlForNationallOffer(hit, context);
    case 'events':
      return getUrlForEvent(hit, context);
    case 'localNews':
      return getUrlForLocalNews(hit, context);
    case 'nationalNews':
      return getUrlForNationalNews(hit, context);
    case 'localStories':
      return getUrlForLocalStory(hit, context);
    case 'nationalStories':
      return getUrlForNationalStory(hit, context);
    case 'centres':
      return getUrlForCentre(hit, context);
    case 'onlineServices':
      return getUrlForOnlineService(hit, context);
    case 'otherStores':
      return getUrlForOtherStore(hit, context);
    case 'westfieldProducts':
      return getUrlForWestfieldProduct(hit, context);
    case 'searchSuggestions':
      return getUrlForSearchSuggestion(hit, context);
    default:
      throw new Error("Unknown segment ".concat(hit.segment));
  }
}