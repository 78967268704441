import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export const geoLocationDistanceInKm = (_ref, _ref2) => {
  let {
    latitude: lat1,
    longitude: lon1
  } = _ref;
  let {
    latitude: lat2,
    longitude: lon2
  } = _ref2;
  // ‘Haversine’ formula to calculate distances between two points (latitude-longitude)
  const earthRadius = 6371; // km
  // Cosine in degrees instead of radians
  const c = degrees => Math.cos(degrees * Math.PI / 180);
  const twoH = 1 - c(lat2 - lat1) + c(lat1) * c(lat2) * (1 - c(lon2 - lon1));
  return 2 * earthRadius * Math.asin(Math.sqrt(twoH / 2));
};
export const getNearestLocations = (fromLocation, locations) => locations.map(location => _objectSpread(_objectSpread({}, location), {}, {
  distance: geoLocationDistanceInKm(fromLocation, location)
})).sort((a, b) => a.distance - b.distance);
export default getNearestLocations;