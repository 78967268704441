import { partition, sortBy, uniq } from 'lodash';
import { Segment } from '../../types';
import { HEADINGS } from '../../group-and-render-segments/headings';
const isString = value => typeof value === 'string';
const getIndexOrLength = (indexFrom, value) => {
  if (!isString(value)) {
    return indexFrom.length;
  }
  const index = indexFrom.indexOf(value);
  return index === -1 ? indexFrom.length : index;
};
const baseItemsToRetailerIds = items => items.map(item => item.retailerId).filter(isString);
function findRetailerOrder(preMergeSources, searchContext) {
  const getSegmentItems = segment => {
    var _preMergeSources$find, _preMergeSources$find2;
    return (_preMergeSources$find = (_preMergeSources$find2 = preMergeSources.find(source => source.sourceId === segment)) === null || _preMergeSources$find2 === void 0 ? void 0 : _preMergeSources$find2.getItems()) !== null && _preMergeSources$find !== void 0 ? _preMergeSources$find : [];
  };
  const {
    nationalStorefronts,
    stores
  } = Segment;
  const relevantRetailerOrder = baseItemsToRetailerIds(getSegmentItems(nationalStorefronts));
  const {
    centre
  } = searchContext;
  if (!centre) {
    return relevantRetailerOrder;
  }
  const retailersInCentre = baseItemsToRetailerIds(getSegmentItems(stores).filter(item => item.centre === centre.sys.id));
  const [relevantRetailersInCentre, relevantRetailersNotInCentre] = partition(relevantRetailerOrder, retailerId => retailersInCentre.indexOf(retailerId) >= 0);
  return uniq([...relevantRetailersInCentre, ...retailersInCentre, ...relevantRetailersNotInCentre]);
}
export function makePostMergeSort(preMergeSources, searchContext) {
  const retailerOrder = findRetailerOrder(preMergeSources, searchContext);
  const segmentOrder = [Segment.stores, Segment.nationalStorefronts];
  return function postMergeSort(items, mergedSourceId) {
    if (mergedSourceId !== HEADINGS.topStores) {
      return items;
    }
    return sortBy(items, [item => getIndexOrLength(retailerOrder, item.retailerId), item => getIndexOrLength(segmentOrder, item.segment)]);
  };
}