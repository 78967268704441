import { incBaseAccount } from '@scentregroup/shared/helpers/paths';
export default function RegisterRedirect() {
  let originPath = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location;
  let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let url = originPath;
  if (typeof url === 'string') {
    url = new URL(url, window.location.origin);
  }
  const redirectSearchParams = new URLSearchParams(url.search);
  for (const [name, value] of Object.entries(params)) {
    if (!value) {
      continue;
    }
    redirectSearchParams.set(name, String(value));
  }
  const path = "".concat(url.pathname, "?").concat(redirectSearchParams.toString()).concat(url.hash);
  const searchParams = new URLSearchParams({
    path
  });
  const redirectUrl = "".concat(url.origin).concat(incBaseAccount('register'), "?").concat(searchParams.toString());
  window.location.assign(redirectUrl);
}