import analyticsUtils from '../../helpers/analytics-utils';
const notUndefined = value => value !== undefined;
export function transformNavigationClickedItemsData(analytics, navigationContext) {
  const {
    itemLabel,
    subParentLabel,
    parentLabel,
    link
  } = navigationContext;
  const text = [itemLabel, subParentLabel, parentLabel].find(notUndefined);
  const hierarchy = [parentLabel, subParentLabel, itemLabel].filter(notUndefined).join(' > ');

  // eslint-disable-next-line no-negated-condition
  const url = link !== '#' ? link : undefined;
  const eventLabel = [parentLabel, itemLabel].filter(notUndefined).join(' - ');
  analytics.trackEvent({
    object: 'Navigation',
    action: 'Clicked',
    properties: {
      label: 'navigation_clicked',
      category: 'Navigation',
      name: analyticsUtils.categories.UNIVERSAL_HEADER,
      text,
      hierarchy,
      url
    },
    legacy: {
      event: 'user_interaction',
      eventCategory: analyticsUtils.categories.UNIVERSAL_HEADER,
      eventAction: analyticsUtils.actions.UNIVERSAL_HEADER_NAV_LINK_CLICK,
      eventLabel
    }
  });
}