import { useState, useCallback } from 'react';
import useFeatureFlag from '@scentregroup/shared/hooks/use-feature-flag';
import { FeatureFlagName } from '@scentregroup/shared/feature-flags';
export default function useSyncState() {
  const {
    0: autocompleteState,
    1: setAutocompleteState
  } = useState({});
  const resetState = () => {
    setAutocompleteState({
      activeItemId: null,
      collections: [],
      completion: null,
      context: {},
      isOpen: false,
      query: '',
      status: 'idle'
    });
  };
  const disableLiveSearch = useFeatureFlag(FeatureFlagName.NEXT_PUBLIC_FEAT_FLAG_DISABLE_LIVE_SEARCH);
  const onStateChange = _ref => {
    let {
      state: newState
    } = _ref;
    setAutocompleteState(oldState => {
      const hasActiveItemId = newState.activeItemId !== null;
      const collectionEmpty = newState.collections.length === 0 && !disableLiveSearch;
      return hasActiveItemId || collectionEmpty ? oldState : newState;
    });
  };
  return {
    autocompleteState,
    onStateChange: useCallback(onStateChange, [disableLiveSearch]),
    resetState: useCallback(resetState, [])
  };
}