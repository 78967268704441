const CENTRE = 'centre';
const ID = 'id';
const SLUG = 'slug';
const CATEGORY = 'category';
const TEMPLATE_PATH = 'templatePath';
const LEGACYIDENTIFIER = 'legacyIdentifier';
const ENTRYID = 'entryId';
const PRODUCT_ID = 'productId';
const DATE = 'date';
const STORE_CAT = 'storeCat';
const STORE_SUBCAT = 'storeSubCat';
const HOMEPAGE = '/';
const TERMS_AND_CONDITIONS = '/terms-and-conditions';
const PRIVACY_POLICY = '/privacy-policy';
const FREE_WIFI = "/free-wifi";
const PRODUCTS = '/products';
const GIFT_CARDS = '/gift-cards';
const DIGITAL_GIFT_CARD = '/shop/westfield-digital-gift-card';
const PRODUCT_COLLECTIONS_LIST = '/shop/collections';
const BROWSE_STORES = "/:".concat(CENTRE, "/browse-stores");
const BROWSE_STORES_WITH_CAT = "/:".concat(CENTRE, "/browse-stores/:").concat(STORE_CAT, "/:").concat(STORE_SUBCAT);
const OPENING_HOURS = "/:".concat(CENTRE, "/opening-hours");
const CENTRE_MAP = "/:".concat(CENTRE, "/centre-map/:").concat(LEGACYIDENTIFIER, "?/:").concat(SLUG, "?");
const PARKING = "/:".concat(CENTRE, "/parking");
const STORE = "/:".concat(CENTRE, "/store/:").concat(ID, "/:").concat(SLUG);
const WHATS_HAPPENING = "/:".concat(CENTRE, "/whats-happening/:").concat(CATEGORY, "?");
const EVENT = "/:".concat(CENTRE, "/event/:").concat(ID, "/:").concat(SLUG);
const NEWS = "/:".concat(CENTRE, "/news/:").concat(ID, "/:").concat(SLUG);
const OFFER = "/:".concat(CENTRE, "/offer/:").concat(ID, "/:").concat(SLUG);
const STORY = "/:".concat(CENTRE, "/story/:").concat(ID, "/:").concat(SLUG);
const SERVICE = "/:".concat(CENTRE, "/service/:").concat(ID, "/:").concat(SLUG);
const MOVIES = "/:".concat(CENTRE, "/movies");
const MOVIE_DETAILS = "/:".concat(CENTRE, "/movie/:").concat(ID, "/:").concat(SLUG, "/:").concat(DATE, "?");
const TEMPLATE = "/:".concat(CENTRE, "/:").concat(TEMPLATE_PATH);
const DINING = "/:".concat(CENTRE, "/dining");
const OLYMPICS = '/olympics';
const PARALYMPICS = '/paralympic-games';

// Account related
const LOGIN = '/login';
const LOGOUT = '/logout';
module.exports = {
  HOMEPAGE,
  CENTRE,
  ID,
  SLUG,
  CATEGORY,
  TEMPLATE_PATH,
  LEGACYIDENTIFIER,
  ENTRYID,
  PRODUCT_ID,
  DATE,
  STORE_CAT,
  STORE_SUBCAT,
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
  FREE_WIFI,
  PRODUCTS,
  GIFT_CARDS,
  DIGITAL_GIFT_CARD,
  PRODUCT_COLLECTIONS_LIST,
  BROWSE_STORES,
  BROWSE_STORES_WITH_CAT,
  OPENING_HOURS,
  CENTRE_MAP,
  PARKING,
  STORE,
  WHATS_HAPPENING,
  EVENT,
  NEWS,
  OFFER,
  STORY,
  SERVICE,
  MOVIES,
  MOVIE_DETAILS,
  TEMPLATE,
  DINING,
  LOGIN,
  LOGOUT,
  OLYMPICS,
  PARALYMPICS
};