import { mapValues } from 'lodash';
function makeResultsByHeadings(segmentResults, getHeadingForSegment) {
  const resultsByHeadings = {};
  Object.keys(segmentResults).forEach(segment => {
    var _segmentResults$segme, _segmentResults$segme2, _segmentResults$segme3, _segmentResults$segme4;
    const hits = (_segmentResults$segme = segmentResults[segment]) === null || _segmentResults$segme === void 0 ? void 0 : _segmentResults$segme.hits;
    // N.B I suspect the following two lines are bugged in the
    // sense that the section under a heading can have multiple
    // segments and thus multiple queryId/indexName but we're only
    // capturing one - but I'm leaving that as a separate issue to
    // fix later.
    const queryId = (_segmentResults$segme2 = (_segmentResults$segme3 = segmentResults[segment]) === null || _segmentResults$segme3 === void 0 ? void 0 : _segmentResults$segme3.queryId) !== null && _segmentResults$segme2 !== void 0 ? _segmentResults$segme2 : '';
    const indexName = (_segmentResults$segme4 = segmentResults[segment]) === null || _segmentResults$segme4 === void 0 ? void 0 : _segmentResults$segme4.indexName;
    if (hits) {
      var _resultsByHeadings$he;
      const heading = getHeadingForSegment(segment);
      resultsByHeadings[heading] = (_resultsByHeadings$he = resultsByHeadings[heading]) !== null && _resultsByHeadings$he !== void 0 ? _resultsByHeadings$he : {
        hits: []
      };
      resultsByHeadings[heading].hits.push(...hits);
      resultsByHeadings[heading].queryId = queryId;
      resultsByHeadings[heading].indexName = indexName;
    }
  });
  return resultsByHeadings;
}
function makeGroupedHitsByHeading(resultsByHeadings, context, onResultClickHeading) {
  return mapValues(resultsByHeadings, (result, heading) => {
    const onResultClick = hit => onResultClickHeading(heading, hit);
    return result.hits.map(hit => ({
      hit,
      onResultClick,
      context
    }));
  });
}
export default function groupSegmentsByHeading(_ref) {
  let {
    segmentResults,
    context,
    onResultClickHeading,
    headingsToDisplay,
    getHeadingForSegment
  } = _ref;
  const resultsByHeadings = makeResultsByHeadings(segmentResults, getHeadingForSegment);
  const groupedHitsByHeading = makeGroupedHitsByHeading(resultsByHeadings, context, onResultClickHeading);
  return headingsToDisplay.filter(heading => groupedHitsByHeading[heading] && groupedHitsByHeading[heading].length > 0).map(heading => {
    var _resultsByHeadings$he2, _resultsByHeadings$he3, _resultsByHeadings$he4, _resultsByHeadings$he5;
    return {
      indexName: (_resultsByHeadings$he2 = (_resultsByHeadings$he3 = resultsByHeadings[heading]) === null || _resultsByHeadings$he3 === void 0 ? void 0 : _resultsByHeadings$he3.indexName) !== null && _resultsByHeadings$he2 !== void 0 ? _resultsByHeadings$he2 : '',
      queryId: (_resultsByHeadings$he4 = (_resultsByHeadings$he5 = resultsByHeadings[heading]) === null || _resultsByHeadings$he5 === void 0 ? void 0 : _resultsByHeadings$he5.queryId) !== null && _resultsByHeadings$he4 !== void 0 ? _resultsByHeadings$he4 : '',
      heading,
      groupedHits: groupedHitsByHeading[heading]
    };
  });
}