export const isNavigationSimpleMenu = item => {
  return Object.hasOwn(item, 'title') && Object.hasOwn(item, 'items') && !isNavigationMegaMenu(item);
};
export const isNavigationLink = item => {
  {
    return Object.hasOwn(item, 'label') && Object.hasOwn(item, 'url');
  }
};
export const isNavigationMegaMenu = item => {
  return Object.hasOwn(item, 'title') && Object.hasOwn(item, 'items') && Object.hasOwn(item, 'feature');
};