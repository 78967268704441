import { flatten, tail } from 'lodash';
import { sgPageTemplateImageTypeValues } from '@scentregroup/shared/types/page-templates';
import { asArray } from './maybe-array';
import { useCurrent } from './use-current';
function getUnmentionedImageTypes(preferredImageType) {
  if (!preferredImageType) {
    return sgPageTemplateImageTypeValues;
  }
  return sgPageTemplateImageTypeValues.filter(imageType => asArray(preferredImageType).indexOf(imageType) === -1);
}
function findBestSrcWithPreferredImageTypes(imageSetOrImage, preferredImageType) {
  if (!Array.isArray(imageSetOrImage)) {
    return imageSetOrImage;
  }
  if (preferredImageType.length === 0) {
    return imageSetOrImage[0];
  }
  const matchingImage = imageSetOrImage.filter(image => image.type === preferredImageType[0])[0];
  if (matchingImage) {
    return matchingImage;
  }
  return findBestSrcWithPreferredImageTypes(imageSetOrImage, tail(preferredImageType));
}
export default function findBestImage(imageSetOrImage, preferredImageType) {
  const unmentionedImageTypes = getUnmentionedImageTypes(preferredImageType);
  const preferredImageTypesWithUnmentionedImageTypes = [...flatten([preferredImageType]), ...unmentionedImageTypes].filter(type => type === 'WIDE' || type === 'SQUARE');
  return findBestSrcWithPreferredImageTypes(imageSetOrImage, preferredImageTypesWithUnmentionedImageTypes);
}
export function useBestImage(imageSetOrImage, preferredImageType) {
  const currentPreferredImageType = useCurrent(preferredImageType);
  return findBestImage(imageSetOrImage, currentPreferredImageType);
}