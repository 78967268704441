// Based on Gtag.js implementation
// https://developers.google.com/analytics/devguides/collection/gtagjs/

// This function is deprecated, instead do:
// const analytics = useAnalytics()
// analytics.trackEvent(event)
const DEPRECATED_gaTagTracking = data => {
  if (false) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer.push(data);
};
export default DEPRECATED_gaTagTracking;