import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React from 'react';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
import { MIN_LIVE_SEARCH_QUERY_LENGTH } from '@scentregroup/shared/constants';
import useAnalyticsUserId from '@scentregroup/shared/hooks/use-analytics-user-id';
import { segmentIndexes, segmentParams, whichClient } from '../query-segments';
import { Segment } from '../types';
import getUrlForHit from '../get-url-for-hit';
import getDisplayNameForHit from '../get-display-name-for-hit';
import { AlgoliaAnalyticsTagsRepositories, AlgoliaAnalyticsTagsUses } from '../algolia-analytics-tags';
import { searchSuggestionsContextFromQueryContext } from '../algolia-helpers';
import useFeatureFlag from '../../../hooks/use-feature-flag';
import { FeatureFlagName } from '../../../feature-flags';
const getAlgoliaResultsMyType = getAlgoliaResults;
const RESULTS_PER_INDEX = 15;
function makeSegmenter(segment) {
  return autocompleteHit => _objectSpread(_objectSpread({}, autocompleteHit), {}, {
    segment
  });
  // this cast is required to convinc TS we've attached the
  // right segment to the right hit, I believe
}

function makeTransformResponse(segment) {
  const segmenter = makeSegmenter(segment);
  const transformResponse = _ref => {
    let {
      hits
    } = _ref;
    const segmented = hits.map(queryResults => queryResults.map(segmenter));
    //  Not sure why the following cast seems to be needed :-/
    return segmented;
  };
  return transformResponse;
}
const makeTransformSearchSuggestionsResponse = context => {
  const transform = _ref2 => {
    var _hitsForFirstQuery$fi, _baseHit$links;
    let {
      hits
    } = _ref2;
    const segmenter = makeSegmenter(Segment.searchSuggestions);
    // Again unsure why all the following casts are needed?
    const hitsForFirstQuery = hits[0];
    const baseHit = (_hitsForFirstQuery$fi = hitsForFirstQuery.find(hit => {
      var _context$centre;
      return hit.centreSlug === ((_context$centre = context.centre) === null || _context$centre === void 0 ? void 0 : _context$centre.slug);
    })) !== null && _hitsForFirstQuery$fi !== void 0 ? _hitsForFirstQuery$fi : hitsForFirstQuery.find(hit => hit.context === searchSuggestionsContextFromQueryContext(context));
    const links = (_baseHit$links = baseHit === null || baseHit === void 0 ? void 0 : baseHit.links) !== null && _baseHit$links !== void 0 ? _baseHit$links : [];
    const segmented = links.map(segmenter);
    return segmented;
  };
  return transform;
};
const analyticsTags = [AlgoliaAnalyticsTagsRepositories.WebsiteClient, AlgoliaAnalyticsTagsUses.LiveSearch];
function getItemsForSegment(searchClient, query, context, segment, userToken) {
  if (query.length < MIN_LIVE_SEARCH_QUERY_LENGTH) {
    return [];
  }
  const searchParams = segmentParams[segment](context);
  // N.B. null indicates this segment should not be searched in this context
  // a lack of parameters is indicated by {}
  if (searchParams === null) {
    return [];
  }
  const indexName = segmentIndexes[segment];
  const queryObject = {
    indexName,
    query,
    params: _objectSpread(_objectSpread({}, searchParams), {}, {
      hitsPerPage: RESULTS_PER_INDEX,
      analyticsTags,
      userToken
    })
  };
  return getAlgoliaResultsMyType({
    searchClient,
    transformResponse: makeTransformResponse(segment),
    queries: [queryObject]
  });
}
const makeSource = _ref3 => {
  let {
    clients,
    context,
    segment,
    userToken
  } = _ref3;
  return {
    sourceId: segment,
    getItems(_ref4) {
      let {
        query
      } = _ref4;
      const client = clients[whichClient(segment)];
      return getItemsForSegment(client, query, context, segment, userToken);
    },
    getItemInputValue(_ref5) {
      let {
        item
      } = _ref5;
      return getDisplayNameForHit(item);
    },
    getItemUrl(_ref6) {
      let {
        item
      } = _ref6;
      return getUrlForHit(item, context);
    },
    onSelect(_ref7) {
      let {
        itemUrl
      } = _ref7;
      if (itemUrl) {
        window.location.assign(itemUrl);
      }
    }
  };
};
const getSearchSuggestionSources = (clients, context, userToken) => [{
  sourceId: Segment.searchSuggestions,
  async getItems() {
    const transformResponse = makeTransformSearchSuggestionsResponse(context);
    const searchParams = segmentParams[Segment.searchSuggestions](context);
    const queryObject = {
      indexName: segmentIndexes[Segment.searchSuggestions],
      query: '*',
      userToken,
      params: _objectSpread(_objectSpread({}, searchParams), {}, {
        hitsPerPage: RESULTS_PER_INDEX
      })
    };
    return getAlgoliaResultsMyType({
      searchClient: clients.content,
      transformResponse,
      queries: [queryObject]
    });
  },
  getItemInputValue(_ref8) {
    let {
      item
    } = _ref8;
    return item.title;
  },
  getItemUrl(_ref9) {
    let {
      item
    } = _ref9;
    return getUrlForHit(item, context);
  },
  onSelect(_ref10) {
    let {
      itemUrl
    } = _ref10;
    if (itemUrl) {
      window.location.assign(itemUrl);
    }
  }
}];
export function makeGetSources(clients, context, segmentsToFetch, userToken) {
  return _ref11 => {
    let {
      query
    } = _ref11;
    if (query.length === 0 && (context.centre || context.specialSearchKind === 'stores')) {
      return getSearchSuggestionSources(clients, context, userToken);
    }
    return segmentsToFetch.map(segment => makeSource({
      clients,
      query,
      context,
      segment,
      userToken
    }));
  };
}
const getSegmentsToFetch = context => {
  const forNationalContext = [Segment.centres, Segment.westfieldProducts, Segment.nationalStorefronts, Segment.otherStores, Segment.onlineServices, Segment.nationalStories, Segment.nationalOffers, Segment.nationalNews];
  const forCentreContext = [Segment.centres, Segment.westfieldProducts, Segment.stores, Segment.otherStores, Segment.nationalStorefronts, Segment.services, Segment.onlineServices, Segment.localNews, Segment.localStories, Segment.events, Segment.localOffers, Segment.sites];
  return context.centre ? forCentreContext : forNationalContext;
};
function useNormalGetSources(clients, context) {
  const userToken = useAnalyticsUserId();
  const getSources = React.useMemo(() => {
    if (!clients) {
      return () => [];
    }
    return params => {
      const segmentsToFetch = getSegmentsToFetch(context);
      return makeGetSources(clients, context, segmentsToFetch, userToken)(params);
    };
  }, [clients, context, userToken]);
  return getSources;
}
export default function useGetSources(clients, context) {
  const isCuratedTopStores = context.query.length === 0 && context.specialSearchKind === 'stores';
  const disableLiveSearch = useFeatureFlag(FeatureFlagName.NEXT_PUBLIC_FEAT_FLAG_DISABLE_LIVE_SEARCH);
  const normalGetSources = useNormalGetSources(clients, context);
  const emptyGetSources = () => [];
  return isCuratedTopStores || !disableLiveSearch ? normalGetSources : emptyGetSources;
}