import DEPRECATED_gaTagTracking from './ga-tag-tracking';
import { events, actions, categories, settings, positions, dimensions, labels, names, affiliations } from './constants';
const analyticsUtils = {
  // This function is deprecated, instead do:
  // const analytics = useAnalytics()
  // analytics.trackEvent(event)
  DEPRECATED_gaTagTracking,
  events,
  actions,
  categories,
  settings,
  positions,
  dimensions,
  labels,
  names,
  affiliations
};
export default analyticsUtils;