import { useState } from 'react';
import useInterval from '@use-it/interval';
function makeTickWhichChild(children, doLoop) {
  return function tickWhichChild(whichChild) {
    if (whichChild < children.length - 1) {
      return whichChild + 1;
    } else if (doLoop) {
      return 0;
    } else {
      return whichChild;
    }
  };
}
const TimedDisplay = _ref => {
  let {
    children,
    timing,
    doLoop
  } = _ref;
  const timingFn = typeof timing === 'function' ? timing : () => timing;
  const {
    0: whichChild,
    1: setWhichChild
  } = useState(0);
  const delay = timingFn(whichChild + 1);
  const tickWhichChild = makeTickWhichChild(children, doLoop);
  useInterval(() => {
    setWhichChild(tickWhichChild);
  }, delay);
  return children[whichChild];
};
export default TimedDisplay;