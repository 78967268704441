import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React from 'react';
import { useAnalyticsSimplified as useAnalytics } from '@scentregroup/analytics';
import { transformNavigationClickedItemsData } from '../../analytics-segment/events/navigation-item';
import analyticsUtils from '../../helpers/analytics-utils';
import trackClickEvent from '../../helpers/analytics-utils/track-event';
import { isNavigationLink, isNavigationMegaMenu, isNavigationSimpleMenu } from '../header/types';
export function useLayoutAnalytics() {
  const analytics = useAnalytics();
  const handleClickNavigationBarLink = React.useCallback((_event, path) => {
    // console.log(
    //   path,
    //   path.map(item => [
    //     isNavigationLink(item),
    //     isNavigationSimpleMenu(item),
    //     isNavigationMegaMenu(item),
    //   ])
    // )
    // _event.preventDefault()

    if (path.length === 1 && isNavigationLink(path[0])) {
      const menuItem = path[0];
      transformNavigationClickedItemsData(analytics, {
        parentLabel: menuItem.label,
        link: menuItem.url
      });
    }
    if (path.length === 2 && isNavigationSimpleMenu(path[0]) && isNavigationLink(path[1])) {
      const itemLabel = path[0].title;
      const menuItem = path[1];
      transformNavigationClickedItemsData(analytics, {
        parentLabel: itemLabel,
        itemLabel: menuItem.label,
        link: menuItem.url
      });
    }
    if (path.length === 2 && isNavigationMegaMenu(path[0]) && isNavigationLink(path[1])) {
      const itemLabel = path[0].title;
      const menuItem = path[1];
      transformNavigationClickedItemsData(analytics, {
        parentLabel: itemLabel,
        itemLabel: menuItem.label,
        link: menuItem.url
      });
    }
    if (path.length === 3 && isNavigationMegaMenu(path[0]) && isNavigationLink(path[2])) {
      const itemLabel = path[0].title;
      const menuItem = path[2];
      transformNavigationClickedItemsData(analytics, {
        parentLabel: itemLabel,
        itemLabel: menuItem.label,
        link: menuItem.url
      });
    }
  }, [analytics]);
  const handleClickMenuLink = React.useCallback((_event, path) => {
    // console.log(
    //   path,
    //   path.map(item => [
    //     isNavigationLink(item),
    //     isNavigationSimpleMenu(item),
    //     isNavigationMegaMenu(item),
    //   ])
    // )
    // _event.preventDefault()
    if (path.length === 2 && isNavigationSimpleMenu(path[0]) && isNavigationLink(path[1])) {
      const parentMenu = path[0];
      const menuItem = path[1];
      const i = parentMenu.items.findIndex(item => item === menuItem);
      trackClickEvent(analyticsUtils.categories.UNIVERSAL_HEADER, analyticsUtils.actions.UNIVERSAL_HEADER_NAV_LINK_CLICK, "L0-".concat(i, " ").concat(menuItem.label));
    }
    if (path.length === 3 && isNavigationSimpleMenu(path[1]) && isNavigationLink(path[2])) {
      const parentMenu = path[1];
      const menuItem = path[2];
      const i = parentMenu.items.findIndex(item => item === menuItem);
      trackClickEvent(analyticsUtils.categories.UNIVERSAL_HEADER, analyticsUtils.actions.UNIVERSAL_HEADER_NAV_LINK_CLICK, "L1-".concat(i, " ").concat(menuItem.label));
    }
    if (path.length === 4 && isNavigationSimpleMenu(path[1]) && isNavigationSimpleMenu(path[2]) && isNavigationLink(path[3])) {
      const topMenu = path[1];
      const parentMenu = path[2];
      const menuItem = path[3];
      const j = topMenu.items.findIndex(item => item === parentMenu);
      const i = parentMenu.items.findIndex(item => item === menuItem);
      trackClickEvent(analyticsUtils.categories.UNIVERSAL_HEADER, analyticsUtils.actions.UNIVERSAL_HEADER_NAV_LINK_CLICK, "L1-".concat(j, "-").concat(i, " ").concat(parentMenu.title, " - ").concat(menuItem.label));
    }
  }, []);
  const handleClickMenuButton = React.useCallback((_event, action, path) => {
    // console.log(
    //   action,
    //   path,
    //   path.map(item => [
    //     isNavigationLink(item),
    //     isNavigationSimpleMenu(item),
    //     isNavigationMegaMenu(item),
    //   ])
    // )
    // _event.preventDefault()
    if (path.length === 2 && path.every(isNavigationSimpleMenu)) {
      const eventAction = {
        open: analyticsUtils.actions.UNIVERSAL_HEADER_NAV_OPEN,
        close: analyticsUtils.actions.UNIVERSAL_HEADER_NAV_CLOSE
      }[action];
      const parentMenu = path[0];
      const menuItem = path[1];
      const i = parentMenu.items.findIndex(item => item === menuItem);
      trackClickEvent(analyticsUtils.categories.UNIVERSAL_HEADER, eventAction, "L0-".concat(i, " ").concat(parentMenu.title, " - ").concat(menuItem.title));
    }
    if (action === 'open' &&
    // to match legacy behaviour only track 'open' events in accordion menus
    path.length === 3 && path.every(isNavigationSimpleMenu)) {
      const eventAction = {
        open: analyticsUtils.actions.UNIVERSAL_HEADER_NAV_OPEN,
        close: analyticsUtils.actions.UNIVERSAL_HEADER_NAV_CLOSE
      }[action];
      const parentMenu = path[1];
      const menuItem = path[2];
      const i = parentMenu.items.findIndex(item => item === menuItem);
      trackClickEvent(analyticsUtils.categories.UNIVERSAL_HEADER, eventAction, "L1-".concat(i, " ").concat(menuItem.title));
    }
  }, []);
  const handleClickLogo = React.useCallback(_event => {
    // _event.preventDefault()
    trackClickEvent(analyticsUtils.categories.UNIVERSAL_HEADER, analyticsUtils.actions.UNIVERSAL_HEADER_NAV_LINK_CLICK, 'Westfield Logo');
  }, []);
  const handleClickSearchSubmit = React.useCallback((query, centre) => {
    analytics.trackEvent({
      object: 'Global',
      action: 'Searched',
      properties: _objectSpread(_objectSpread({
        category: 'Search',
        label: 'global_searched',
        search_type: centre ? 'Centre' : 'Westfield Direct'
      }, centre && {
        centre_context: centre.title
      }), {}, {
        keyword: query
      })
    });
  }, [analytics]);
  return {
    handleClickNavigationBarLink,
    handleClickMenuLink,
    handleClickMenuButton,
    handleClickLogo,
    handleClickSearchSubmit
  };
}