import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ViewOption } from '../../constants';
import { AlgoliaAnalyticsTagsRepositories, AlgoliaAnalyticsTagsUses } from './algolia-analytics-tags';
import { searchSuggestionsContextFromQueryContext } from './algolia-helpers';
// The queries we run whenever text changes, and how the results turn into dom.
// Everything here is passed to algolia except for the render method.
const centreWithDateAndViewOptionFilter = (centreID, viewOptionToExclude, getOffsetlessTimeInCentre) => ({
  filters: "centres:".concat(centreID, " AND (NOT viewOption:\"").concat(viewOptionToExclude, "\") AND (showAt<").concat(getOffsetlessTimeInCentre(), " AND hideAt>").concat(getOffsetlessTimeInCentre(), ")")
});
const countryWithDateAndViewOptionFilter = (country, viewOptionToExclude, getOffsetlessTimeInDefaultTz) => ({
  filters: "countries:\"".concat(country, "\" AND (NOT viewOption:\"").concat(viewOptionToExclude, "\") AND (showAt<").concat(getOffsetlessTimeInDefaultTz(), " AND hideAt>").concat(getOffsetlessTimeInDefaultTz(), ")")
});
const singleCentreWithDateFilter = (centreID, getOffsetlessTimeInCentre) => ({
  filters: "centre:".concat(centreID, " AND (showAt<").concat(getOffsetlessTimeInCentre(), " AND hideAt>").concat(getOffsetlessTimeInCentre(), ")")
});
function isCentreSectionContext(context) {
  return Boolean(context === null || context === void 0 ? void 0 : context.centre) && Boolean(context === null || context === void 0 ? void 0 : context.getOffsetlessTimeInCentre);
}
function nullIfNotCentre(f) {
  return function (context) {
    return isCentreSectionContext(context) ? f(context) : null;
  };
}
const basicCentreFilter = nullIfNotCentre(context => singleCentreWithDateFilter(context.centre.sys.id, context.getOffsetlessTimeInCentre));
const justCountryFilter = _ref => {
  let {
    country
  } = _ref;
  return {
    filters: "country:\"".concat(country, "\"")
  };
};
const viewOptionToExclude = user => user !== null && user !== void 0 && user.isLoggedIn ? ViewOption.HIDE_FROM_MEMBERS : ViewOption.MEMBERS_ONLY;
const viewOptionCentreFilter = nullIfNotCentre(context => centreWithDateAndViewOptionFilter(context.centre.sys.id, viewOptionToExclude(context.user), context.getOffsetlessTimeInCentre));
const viewOptionCountryFilter = context => countryWithDateAndViewOptionFilter(context.country, viewOptionToExclude(context.user), context.getOffsetlessTimeInDefaultTz);
const makeMarketplaceFilter = params => function marketplaceFilter(context) {
  return context.country === 'Australia' ? params : null;
};
const searchSuggestionsFilter = context => {
  var _context$centre;
  const centreSlug = (_context$centre = context.centre) === null || _context$centre === void 0 ? void 0 : _context$centre.slug;
  const isCentreAndNotStores = centreSlug && context.specialSearchKind !== 'stores';
  // N.B. context the searchSuggestion property we're querying here is
  // different to the QueryContext object we've been passed as a param
  const defaultSuffix = "context:'".concat(searchSuggestionsContextFromQueryContext(context), "'");
  const centrePrefix = isCentreAndNotStores ? "centreSlug:'".concat(centreSlug, "' OR ") : '';
  return {
    filters: "".concat(centrePrefix).concat(defaultSuffix)
  };
};
const onlineServicesFilter = context => context.country === 'New Zealand' ? {
  filters: 'isEvolvedOnly: false'
} : {};
export const segmentParams = {
  nationalStorefronts: justCountryFilter,
  stores: basicCentreFilter,
  sites: nullIfNotCentre(() => ({})),
  services: basicCentreFilter,
  localOffers: viewOptionCentreFilter,
  nationalOffers: viewOptionCountryFilter,
  events: viewOptionCentreFilter,
  localNews: viewOptionCentreFilter,
  nationalNews: viewOptionCountryFilter,
  localStories: viewOptionCentreFilter,
  nationalStories: viewOptionCountryFilter,
  centres: justCountryFilter,
  onlineServices: onlineServicesFilter,
  otherStores: justCountryFilter,
  westfieldProducts: makeMarketplaceFilter({
    removeWordsIfNoResults: 'allOptional'
  }),
  searchSuggestions: searchSuggestionsFilter
};
export const segmentIndexes = {
  nationalStorefronts: 'nationalStorefront',
  stores: 'store',
  sites: 'site',
  services: 'service',
  localOffers: 'offer',
  nationalOffers: 'offer',
  events: 'event',
  localNews: 'news',
  nationalNews: 'news',
  localStories: 'story',
  nationalStories: 'story',
  centres: 'centre',
  onlineServices: 'onlineService',
  otherStores: 'store',
  westfieldProducts: 'westfieldProduct',
  searchSuggestions: 'searchSuggestions'
};
export const whichClient = _segment => 'content';
const processResults = (segment, _ref2) => {
  let {
    hits,
    index,
    queryID
  } = _ref2;
  return {
    indexName: index !== null && index !== void 0 ? index : '',
    queryId: queryID !== null && queryID !== void 0 ? queryID : '',
    hits: hits.map(hit => _objectSpread(_objectSpread({}, hit), {}, {
      segment
    }))
  };
};
const analyticsTags = [AlgoliaAnalyticsTagsRepositories.WebsiteClient, AlgoliaAnalyticsTagsUses.SiteSearch];
function getSegmentsAndQueriesByClient(segments, context, queryText) {
  const queries = {
    content: []
  };
  const segmentsByClient = {
    content: []
  };
  segments.forEach(segment => {
    const params = segmentParams[segment](context);
    if (params) {
      const indexName = segmentIndexes[segment];
      queries[whichClient(segment)].push({
        query: queryText,
        params: _objectSpread(_objectSpread({}, params), {}, {
          clickAnalytics: true,
          analyticsTags
        }),
        indexName
      });
      segmentsByClient[whichClient(segment)].push(segment); // record the shape for the response
    }
  });

  return {
    segmentsByClient,
    queries
  };
}
function isSearchResponse(response) {
  return Boolean(response.hits);
}
export default async function querySegments(queryText, clients, context, segmentsToQuery) {
  const {
    segmentsByClient,
    queries
  } = getSegmentsAndQueriesByClient(segmentsToQuery, context, queryText);
  const [contentResponse] = await Promise.all([queries.content.length ? clients.content.search(queries.content) : null]);
  const processedResults = {};
  contentResponse === null || contentResponse === void 0 || contentResponse.results.filter(isSearchResponse).forEach((raw, i) => {
    const segment = segmentsByClient.content[i];
    processedResults[segment] = processResults(segment, raw);
  });
  return processedResults;
}