import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useCallback } from 'react';
import { groupBy } from 'lodash';
import { assertSegment } from '../../types';
import { makeHeadingInfo } from '../../group-and-render-segments/headings';
import { orderResults } from '../../result-order';
import { makePostMergeSort } from './make-post-merge-sort';
import { substituteSources } from './substitute-sources';
function mergeSources(mergedSourceId, sources, sort) {
  const sourcesBySourceId = {};
  sources.forEach(source => {
    sourcesBySourceId[source.sourceId] = source;
  });
  const assertGetSource = item => {
    const sourceId = item._originalSourceId;
    const source = sourcesBySourceId[sourceId];
    if (source) {
      return source;
    } else {
      throw new Error("Source not found for _originalSourceId: ".concat(sourceId));
    }
  };
  return {
    sourceId: mergedSourceId,
    getItems() {
      const unsortedItems = sources.map(source => source.getItems().map(item => _objectSpread(_objectSpread({}, item), {}, {
        _originalSourceId: source.sourceId
      }))).flat();
      return sort(unsortedItems, mergedSourceId);
    },
    getItemInputValue(props) {
      var _assertGetSource$getI, _assertGetSource$getI2, _assertGetSource;
      return (_assertGetSource$getI = (_assertGetSource$getI2 = (_assertGetSource = assertGetSource(props.item)).getItemInputValue) === null || _assertGetSource$getI2 === void 0 ? void 0 : _assertGetSource$getI2.call(_assertGetSource, props)) !== null && _assertGetSource$getI !== void 0 ? _assertGetSource$getI : props.state.query;
    },
    getItemUrl(props) {
      var _assertGetSource$getI3, _assertGetSource2;
      return (_assertGetSource$getI3 = (_assertGetSource2 = assertGetSource(props.item)).getItemUrl) === null || _assertGetSource$getI3 === void 0 ? void 0 : _assertGetSource$getI3.call(_assertGetSource2, props);
    },
    onActive(props) {
      var _assertGetSource$onAc, _assertGetSource3;
      return (_assertGetSource$onAc = (_assertGetSource3 = assertGetSource(props.item)).onActive) === null || _assertGetSource$onAc === void 0 ? void 0 : _assertGetSource$onAc.call(_assertGetSource3, props);
    },
    onSelect(props) {
      var _assertGetSource$onSe, _assertGetSource4;
      return (_assertGetSource$onSe = (_assertGetSource4 = assertGetSource(props.item)).onSelect) === null || _assertGetSource$onSe === void 0 ? void 0 : _assertGetSource$onSe.call(_assertGetSource4, props);
    }
  };
}
export function makeReshape(groupSourceId, categorySlugToHeaderMapping, context) {
  return function reshape(_ref) {
    let {
      sources
    } = _ref;
    const sortDuringMerge = makePostMergeSort(sources, context);
    const grouped = groupBy(sources, source => groupSourceId(source.sourceId));
    const entries = Object.entries(grouped);
    const mergedSources = entries.map(_ref2 => {
      let [groupedSourceId, sources] = _ref2;
      return mergeSources(groupedSourceId, sources, sortDuringMerge);
    });
    const substitutedSources = substituteSources(mergedSources);
    return orderResults(substitutedSources, context, 'live', mergedSources => mergedSources.sourceId);
  };
}
export default function useReshape(context) {
  let categorySlugToHeaderMapping = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const groupSourceId = useCallback(sourceId => {
    const {
      getHeadingForSegment
    } = makeHeadingInfo(context);
    return getHeadingForSegment(assertSegment(sourceId));
  }, [context]);
  return makeReshape(groupSourceId, categorySlugToHeaderMapping, context);
}