import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import { RawCookieName } from '../constants';
import useOnce from './use-once';
import { useGetHrefContext } from '../context';
const {
  SCENTREGROUP_ID,
  GOOGLE_ANALYTICS
} = RawCookieName;
function extractGaClientId(gaCookieValue) {
  return (gaCookieValue === null || gaCookieValue === void 0 ? void 0 : gaCookieValue.split('.').splice(2).join('-')) || null;
}
function useGetQueryParams() {
  try {
    const href = useGetHrefContext()();
    const url = new URL(href);
    return url.searchParams;
  } catch (e) {
    return null;
  }
}

/*
N.B.!!
This algorithm for determining the ID is the same used when calculating 
userTokens for Algolia in Google Tag manager. Any changes to this code
must be reflected in GTM and vice versa!
*/

export default function useAnalyticsUserId() {
  const backupDepulicator = useOnce(() => uuidv4());
  const [cookies] = useCookies([SCENTREGROUP_ID, GOOGLE_ANALYTICS]);
  const query = useGetQueryParams();
  const result = cookies[SCENTREGROUP_ID] || (query === null || query === void 0 ? void 0 : query.get('scg_id')) || extractGaClientId(cookies[GOOGLE_ANALYTICS]) || "No analytics user id found (".concat(backupDepulicator, ")");
  return result;
}