import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useState, useMemo, useCallback } from 'react';
import { useRecentCentres } from '../hooks/use-recent-centres';
import analyticsUtils from '../helpers/analytics-utils';
import getNearestLocations from '../helpers/get-nearest-locations';
export const useNearestCentres = function (centres) {
  let MAX_SUGGESTIONS = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
  const recentCentres = useRecentCentres();
  const {
    0: loadingLocation,
    1: setLoadingLocation
  } = useState(false);
  const maxSuggests = useMemo(() => MAX_SUGGESTIONS, [MAX_SUGGESTIONS]);
  const getCentreDisplayTitle = centre => {
    if (!centre) {
      return '';
    }
    const location = centre.suburb === centre.title ? centre.state : "".concat(centre.suburb, ", ").concat(centre.state);
    return "".concat(centre.title, ", ").concat(location);
  };

  // Inject a `.displayTitle` field for better rendering
  const centresWithDisplayTitle = useMemo(() => (centres !== null && centres !== void 0 ? centres : []).map(centre => _objectSpread(_objectSpread({}, centre), {}, {
    displayTitle: getCentreDisplayTitle(centre)
  })), [centres]);
  const {
    0: suggestedCentres,
    1: setSuggestedCentres
  } = useState(() => {
    return recentCentres
    // Convert it into a complete object
    .map(slug => centresWithDisplayTitle.find(centre => centre.slug === slug))
    // Clear out any bad data
    .filter(centre => Boolean(centre))
    // Limit the number of suggestions we return
    .slice(0, maxSuggests);
  });
  const getSuggestedCentresNearMe = useCallback(() => {
    setLoadingLocation(true);
    analyticsUtils.DEPRECATED_gaTagTracking({
      event: analyticsUtils.events.SEARCH,
      eventCategory: analyticsUtils.categories.CENTRE_SEARCH,
      eventAction: analyticsUtils.actions.CLICK_TO_SET_LOCATION
    });
    try {
      // For browsers that do not support geolocation
      navigator.geolocation.getCurrentPosition(position => {
        const nearestCentres = getNearestLocations(position.coords, centresWithDisplayTitle).slice(0, maxSuggests);
        setSuggestedCentres(nearestCentres);
        setLoadingLocation(false);
      }, () => {
        setLoadingLocation(false);
      }, {
        timeout: 10000
      });
    } catch (e) {
      setLoadingLocation(false);
      // TODO: Verify this is the right event
      analyticsUtils.DEPRECATED_gaTagTracking({
        event: analyticsUtils.events.SEARCH,
        eventCategory: analyticsUtils.categories.CENTRE_SEARCH,
        eventAction: analyticsUtils.actions.CLICK_TO_SET_LOCATION_DENIED
      });
    }
  }, [centresWithDisplayTitle, maxSuggests]);
  return [{
    loadingLocation,
    suggestedCentres
  }, getSuggestedCentresNearMe];
};