// These are the same as we're now deciding which one is enabled in the
// nginx configuration, as they live on the same path.
const prefixAccount = '/account';
export function incBaseAccount(path) {
  return prependPrefix(path, prefixAccount);
}
function prependPrefix(path, prefix) {
  const separator = path.startsWith('/') || path === '' ? '' : '/';
  return "".concat(prefix).concat(separator).concat(path);
}
function isPrefixedPath(path, prefix) {
  return new RegExp("".concat(prefix, "(\\/.*)?$")).test(path);
}
export function isAccountPath(path) {
  return isPrefixedPath(path, prefixAccount);
}
export function removeAccountPrefix(path) {
  return path.replace(/^\/?account(\/|$)/, '');
}