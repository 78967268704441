import { HEADINGS } from './group-and-render-segments/headings';
export const liveSearchCentreHeadingOrder = [HEADINGS.centres, HEADINGS.westfieldProducts, HEADINGS.westfieldServices, HEADINGS.topStores, HEADINGS.otherStores, HEADINGS.news, HEADINGS.stories, HEADINGS.events, HEADINGS.offers];
export const liveSearchNationalHeadingOrder = [HEADINGS.centres, HEADINGS.westfieldProducts, HEADINGS.westfieldServices, HEADINGS.topStores, HEADINGS.otherStores, HEADINGS.news, HEADINGS.stories, HEADINGS.events, HEADINGS.offers];
export const liveSearchFindAStoreHeadingOrder = [HEADINGS.topStores, HEADINGS.otherStores, HEADINGS.centres, HEADINGS.westfieldProducts, HEADINGS.westfieldServices, HEADINGS.news, HEADINGS.stories, HEADINGS.events, HEADINGS.offers];
export const resultsPageCentreHeadingOrder = [HEADINGS.centres, HEADINGS.westfieldProducts, HEADINGS.westfieldServices, HEADINGS.topStores, HEADINGS.otherStores, HEADINGS.news, HEADINGS.stories, HEADINGS.events, HEADINGS.offers];
export const resultsPageNationalHeadingOrder = [HEADINGS.centres, HEADINGS.westfieldProducts, HEADINGS.westfieldServices, HEADINGS.topStores, HEADINGS.otherStores, HEADINGS.news, HEADINGS.stories, HEADINGS.events, HEADINGS.offers];
export function orderResults(results, context, searchType, getHeading) {
  const headingOrder = selectOrder(context, searchType);
  results.sort((a, b) => headingOrder.indexOf(getOfficalHeading(headingOrder, getHeading, a)) - headingOrder.indexOf(getOfficalHeading(headingOrder, getHeading, b)));
  return results;
}
function getOfficalHeading(headingOrder, getHeading, item) {
  const heading = getHeading(item);
  const index = headingOrder.indexOf(heading);
  if (index >= 0) {
    return headingOrder[index];
  } else {
    var _headingOrder$find;
    return (_headingOrder$find = headingOrder.find(el => heading.includes(el))) !== null && _headingOrder$find !== void 0 ? _headingOrder$find : HEADINGS.noResult;
  }
}
function selectOrder(context, searchType) {
  const isCentre = !!context.centre;
  const isLiveSearch = searchType === 'live';
  const isFindAStore = context.specialSearchKind === 'stores';
  if (!isLiveSearch && isCentre) {
    return resultsPageCentreHeadingOrder;
  } else if (!isLiveSearch && !isCentre) {
    return resultsPageNationalHeadingOrder;
  } else if (isFindAStore) {
    return liveSearchFindAStoreHeadingOrder;
  } else if (isCentre) {
    return liveSearchCentreHeadingOrder;
  } else {
    return liveSearchNationalHeadingOrder;
  }
}