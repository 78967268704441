export const HEADINGS = {
  centres: 'Centres',
  westfieldProducts: 'Westfield Gift Cards',
  westfieldServices: 'Westfield Services',
  topStores: 'Top Stores',
  events: 'Events',
  offers: 'Offers',
  news: 'News',
  stories: 'Stories',
  otherStores: 'Other stores',
  popularSearches: 'Popular Searches',
  noResult: ''
};
const getSegmentsToHeadings = (headings, context) => ({
  westfieldProducts: headings.westfieldProducts,
  nationalStorefronts: headings.topStores,
  stores: headings.topStores,
  sites: headings.westfieldServices,
  services: headings.westfieldServices,
  localOffers: headings.offers,
  nationalOffers: headings.offers,
  events: headings.events,
  localNews: headings.news,
  nationalNews: headings.news,
  localStories: headings.stories,
  nationalStories: headings.stories,
  centres: headings.centres,
  onlineServices: headings.westfieldServices,
  otherStores: headings.otherStores,
  searchSuggestions: context.specialSearchKind === 'stores' ? headings.topStores : headings.popularSearches
});
export function makeHeadingInfo(context) {
  const headings = HEADINGS;
  const segmentToHeadings = getSegmentsToHeadings(headings, context);
  const getHeadingForSegment = segment => segmentToHeadings[segment];
  const headingsToDisplay = Object.values(headings);
  return {
    getHeadingForSegment,
    headingsToDisplay
  };
}