export const completelyCanonicalUrl = function (url) {
  let approvedParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  let defaultParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  // URL doesn't support not providing a protocol so www.example.com as input will fail without this
  let updatedUrlString = url;
  if (!url.match(/^[a-zA-Z]+?:\/\//)) {
    updatedUrlString = "https://".concat(updatedUrlString);
  }
  const updatedUrl = new URL(updatedUrlString);
  updatedUrl.protocol = 'https:';
  updatedUrl.pathname = updatedUrl.pathname.replace(/\/$/, '');
  updatedUrl.hostname = /^[^.]+(\.com(?:\.au)?|\.co\.nz)$/.test(updatedUrl.hostname) ? "www.".concat(updatedUrl.hostname) : updatedUrl.hostname;
  updatedUrl.username = '';
  updatedUrl.password = '';
  updatedUrl.hash = '';
  if (approvedParams && approvedParams.length > 0) {
    const searchParams = new URLSearchParams(updatedUrl.search);
    const entries = [...searchParams.entries()];
    const filteredEntries = entries.filter(key => approvedParams.includes(key[0]));
    const filteredSearchParams = new URLSearchParams();
    filteredEntries.forEach(entry => filteredSearchParams.append(...entry));
    if (defaultParams) {
      Object.keys(defaultParams).forEach(key => {
        if (!filteredSearchParams.has(key) && approvedParams.includes(key)) {
          filteredSearchParams.append(key, defaultParams[key].toString());
        }
      });
    }
    filteredSearchParams.sort();
    updatedUrl.search = filteredSearchParams.toString();
  } else {
    updatedUrl.search = '';
  }
  return updatedUrl.toString();
};