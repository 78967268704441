import { cfTopStripToTopStripProps } from './types';
import { GetTopStripDocument } from '../../graphql';
async function getTopStripProps(client, country, centre) {
  const isCentre = Boolean(centre);
  const {
    data
  } = await client.query({
    query: GetTopStripDocument,
    variables: {
      country,
      isCentre
    }
  });
  if (!data.cfTopStripCollection) {
    return null;
  }
  const cfTopStrip = data.cfTopStripCollection.items.find(item => {
    var _item$centresCollecti;
    if (!item) {
      return false;
    }
    if (isCentre && (_item$centresCollecti = item.centresCollection) !== null && _item$centresCollecti !== void 0 && _item$centresCollecti.items.find(item => (item === null || item === void 0 ? void 0 : item.slug) === centre)) {
      return true;
    }
    return !isCentre;
  });
  if (!cfTopStrip && isCentre) {
    return getTopStripProps(client, country);
  }
  if (!cfTopStrip) {
    return null;
  }
  return cfTopStripToTopStripProps(cfTopStrip);
}
export default getTopStripProps;