import { useQuery } from '@apollo/client';
import { cfTopStripToTopStripProps } from './types';
import { GetTopStripDocument } from '../../graphql';
function useTopStripProps(country) {
  var _data$cfTopStripColle;
  const {
    data
  } = useQuery(GetTopStripDocument, {
    variables: {
      country,
      isCentre: false
    }
  });
  const cfTopStrip = data === null || data === void 0 || (_data$cfTopStripColle = data.cfTopStripCollection) === null || _data$cfTopStripColle === void 0 ? void 0 : _data$cfTopStripColle.items[0];
  if (!cfTopStrip) {
    return null;
  }
  return cfTopStripToTopStripProps(cfTopStrip);
}
export default useTopStripProps;