export default [{
  name: 'Airport West',
  slug: 'airportwest',
  country: 'Kulin Nation',
  content: 'We acknowledge the Wurundjeri people of the Kulin Nation as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Doncaster',
  slug: 'doncaster',
  country: 'Kulin Nation',
  content: 'We acknowledge the Wurundjeri people of the Kulin Nation as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Fountain Gate',
  slug: 'fountaingate',
  country: 'Kulin Nation',
  content: 'We acknowledge the Bunurong people of the Kulin Nation as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Geelong',
  slug: 'geelong',
  country: 'Wadawurrung Country',
  content: 'We acknowledge the Wadawurrung people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Knox',
  slug: 'knox',
  country: 'Kulin Nation',
  content: 'We acknowledge the Wurundjeri people of the Kulin Nation as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Plenty Valley',
  slug: 'plentyvalley',
  country: 'Kulin Nation',
  content: 'We acknowledge the Wurundjeri people of the Kulin Nation as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Southland',
  slug: 'southland',
  country: 'Kulin Nation',
  content: 'We acknowledge the Bunurong people of the Kulin Nation as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Marion',
  slug: 'marion',
  country: 'Kaurna Country',
  content: 'We acknowledge the Kaurna people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Tea Tree Plaza',
  slug: 'teatreeplaza',
  country: 'Kaurna Country',
  content: 'We acknowledge the Kaurna people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'West Lakes ',
  slug: 'westlakes',
  country: 'Kaurna Country',
  content: 'We acknowledge the Kaurna people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Booragoon',
  slug: 'booragoon',
  country: 'Whadjuk Noongar Country',
  content: 'We acknowledge the Whadjuk Noongar people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Carousel',
  slug: 'carousel',
  country: 'Whadjuk Noongar Country',
  content: 'We acknowledge the Whadjuk Noongar people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Innaloo',
  slug: 'innaloo',
  country: 'Whadjuk Noongar Country',
  content: 'We acknowledge the Whadjuk Noongar people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Whitford City',
  slug: 'whitfordcity',
  country: 'Whadjuk Noongar Country',
  content: 'We acknowledge the Whadjuk Noongar people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Belconnen',
  slug: 'belconnen',
  content: 'We acknowledge the Traditional Owners of Country throughout Australia and pay our respect to Aboriginal & Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Woden',
  slug: 'woden',
  content: 'We acknowledge the Traditional Owners of Country throughout Australia and pay our respect to Aboriginal & Torres Strait Islander cultures and their Elders past and present'
}, {
  name: 'Carindale',
  slug: 'carindale',
  country: 'Turrbal and Yuggera Country',
  content: 'We acknowledge the Turrbal and Yuggera people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Chermside',
  slug: 'chermside',
  country: 'Turrbal and Yuggera Country',
  content: 'We acknowledge the Turrbal and Yuggera people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Coomera',
  slug: 'coomera',
  country: 'Yugambeh language region',
  content: 'We acknowledge the Yugambeh language region, the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Helensvale',
  slug: 'helensvale',
  country: 'Yugambeh language region',
  content: 'We acknowledge the Yugambeh language region, the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Mt Gravatt',
  slug: 'mtgravatt',
  country: 'Turrbal and Yuggera Country',
  content: 'We acknowledge the Turrbal and Yuggera people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'North Lakes',
  slug: 'northlakes',
  country: 'Kabi Kabi, Jinibara and Turrbal Country',
  content: 'We acknowledge the Kabi Kabi, Jinibara and Turrbal people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Bondi Junction',
  slug: 'bondijunction',
  country: 'Gadigal and Birrabirragal Country',
  content: 'We acknowledge the Gadigal and Birrabirragal people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Burwood',
  slug: 'burwood',
  country: 'Eora Nation',
  content: 'We acknowledge the Wangal people of the Eora Nation as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Chatswood',
  slug: 'chatswood',
  country: 'Cammeraygal Country',
  content: 'We acknowledge the Cammeraygal people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Eastgardens',
  slug: 'eastgardens',
  country: 'Bidjigal Country',
  content: 'We acknowledge the Bidjigal people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Hornsby',
  slug: 'hornsby',
  country: 'Darug and Guringai Country',
  content: 'We acknowledge the Darug and Guringai peoples as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Hurstville',
  slug: 'hurstville',
  country: 'Bidjigal Country',
  content: 'We acknowledge the Bidjigal people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Kotara',
  slug: 'kotara',
  country: 'Awabakal Country',
  content: 'We acknowledge the Awabakal people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Liverpool',
  slug: 'liverpool',
  country: 'Darug Nation',
  content: 'We acknowledge the Cabrogal people of the Darug Nation as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Miranda',
  slug: 'miranda',
  country: 'Dharawal Country',
  content: 'We acknowledge the Dharawal people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Mt Druitt',
  slug: 'mtdruitt',
  country: 'Darug Country',
  content: 'We acknowledge the Darug people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Parramatta',
  slug: 'parramatta',
  country: 'Darug Country',
  content: 'We acknowledge the Darug people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Penrith',
  slug: 'penrith',
  country: 'Darug Country',
  content: 'We acknowledge the Darug people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Sydney',
  slug: 'sydney',
  country: 'Eora Nation',
  content: 'We acknowledge the Gadigal people of the Eora Nation as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Tuggerah',
  slug: 'tuggerah',
  country: 'Darkinjung Country',
  content: 'We acknowledge the Darkinjung people as the Traditional Owners of the land we gather on today and pay our respects to Aboriginal and Torres Strait Islander cultures and their Elders past and present.'
}, {
  name: 'Warringah Mall',
  slug: 'warringahmall',
  country: 'Eora Nation',
  content: 'Westfield Warringah Mall wishes to acknowledge the Traditional Owners, the Gayamaygal and the Garigal and their Country. By these words we would also like to show our respect to all Aboriginal people. We Acknowledge the Elders and acknowledge the spirits and ancestors of the Clans that lived in this area.'
}];