import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { DateTime, isToday, isTomorrow } from './date-helpers';
import { HourStatus } from '@scentregroup/shared/graphql';
export const TODAY_STRING = 'Today';
export const YESTERDAY_STRING = 'Yesterday';
export const TOMORROW_STRING = 'Tomorrow';
export const formatTimeAMPM = dt => dt.format('h[.]mma');
export const formatDay = dt => dt.clone().format('ddd');
export const formatDayNumber = dt => dt.clone().format('D');
export const formatMonth = dt => dt.clone().format('MMM');
export const formatDayAndMonth = dt => dt.format('D MMM');
export const formatYear = dt => dt.clone().format('YYYY');
export const formatISOWithTime = dt => dt.clone().format('YYYY-MM-DDTHH:mm');

// Mon 19th Feb, Wed 21st Feb ...
export const formatTradingHoursDate = dt => "".concat(formatDay(dt.clone()), " ").concat(formatDayNumber(dt.clone()), " ").concat(formatMonth(dt.clone()));

// Today
// Tomorrow
// Mon 19th, Wed 21st ...
// Mon 19th Feb, Wed 21st Feb ...
export const formatHumanDate = function (dt) {
  let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (isToday(dt, opts.timeZone)) {
    return TODAY_STRING;
  }
  if (isTomorrow(dt, opts.timeZone)) {
    return TOMORROW_STRING;
  }
  let str = "".concat(formatDay(dt.clone()), " ").concat(formatDayNumber(dt.clone()));
  if (opts.includeMonth) {
    str = "".concat(str, " ").concat(formatMonth(dt.clone()));
  }
  return str;
};

// 9:13am, 2:25pm ...
export const formatTime = dt => dt.clone().format('h:mma').toLowerCase();

// expects date as String ISO 8601 format e.g. 2018-05-14T08:00:00.000Z
export const howLongAgo = (date, timeZone) => {
  const today = DateTime.local({
    timeZone
  }).endOf('day');
  const dt = DateTime.fromISO(date, {
    timeZone
  });
  const duration = DateTime.moment.duration(dt.diff(today));
  if (duration.days() > -1) {
    return TODAY_STRING;
  }
  // eslint-disable-next-line no-magic-numbers
  if (duration.days() > -2) {
    return YESTERDAY_STRING;
  }
  return duration.humanize(true);
};
export const formatTimes = (tradingHourDays, timeZone) => tradingHourDays.map(tradingHourDay => {
  var _tradingHourDay$hourS, _tradingHourDay$descr;
  return _objectSpread(_objectSpread({}, tradingHourDay), {}, {
    hourStatus: (_tradingHourDay$hourS = tradingHourDay.hourStatus) !== null && _tradingHourDay$hourS !== void 0 ? _tradingHourDay$hourS : HourStatus.Normal,
    description: (_tradingHourDay$descr = tradingHourDay.description) !== null && _tradingHourDay$descr !== void 0 ? _tradingHourDay$descr : '',
    openingTime: formatTime(DateTime.fromISO("".concat(tradingHourDay.date, "T").concat(tradingHourDay.openingTime), {
      timeZone,
      format: 'HH:mm'
    })),
    closingTime: formatTime(DateTime.fromISO("".concat(tradingHourDay.date, "T").concat(tradingHourDay.closingTime), {
      timeZone,
      format: 'HH:mm'
    }))
  });
});