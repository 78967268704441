import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { useTheme, useToken } from '@hub/design-system-base';
/**
 * generate the <img> 'sizes' prop string in various ways
 *
 * sizes is empty will return the max fixed width (normally not what is wanted)
 * sizes is a string will return the string; this allows using custom breakpoints
 * sizes is a number will return the number in pixels; used for images that have a fixed width
 * sizes is an array maps the array values to theme breakpoints; use null to skip a breakpoint
 *   so the breakpoint uses the same size as the previous
 * sizes is an object using theme breakpoint names as keys will map sizes to theme breakpoints
 */
export function useImageSizes(sizes) {
  var _theme$__breakpoints;
  const maxFixedWidth = useToken('sizes', 'size-max-fixed');
  const theme = useTheme();
  const breakpoints = (_theme$__breakpoints = theme.__breakpoints) === null || _theme$__breakpoints === void 0 ? void 0 : _theme$__breakpoints.normalized;
  const INHERIT_VALUE_FROM_PREVIOUS_BREAKPOINT_PLACEHOLDER = 'NO VALUE';
  const sizesWithPlaceholders = Array.isArray(sizes) ? [sizes].flatMap(s => s).map(s => s || INHERIT_VALUE_FROM_PREVIOUS_BREAKPOINT_PLACEHOLDER) : [];
  const sizeValues = useToken('sizes', sizesWithPlaceholders);
  if (!breakpoints) {
    return "".concat(maxFixedWidth, "px");
  }
  if (typeof sizes === 'string') {
    return sizes;
  }
  if (typeof sizes === 'number') {
    return "".concat(sizes, "px");
  }
  if (typeof sizes !== 'object') {
    return "".concat(maxFixedWidth, "px");
  }
  if (Array.isArray(sizes)) {
    if (!(breakpoints !== null && breakpoints !== void 0 && breakpoints.length)) {
      return "".concat(maxFixedWidth, "px");
    }
    if (sizeValues.length === 0) {
      return "".concat(maxFixedWidth, "px");
    }
    const [defaultSize] = sizeValues.slice(-1);
    return sizeValues.slice(0, -1).map((size, i) => {
      if (size === null) {
        return null;
      }
      const width = typeof size === 'string' ? size : "".concat(size, "px");
      return "(max-width: calc(".concat(breakpoints[i + 1], " - 1px)) ").concat(width);
    }).filter(s => s !== null).concat(typeof defaultSize === 'string' ? defaultSize : "".concat(defaultSize, "px")).join(', ');
  }
  const entries = Object.entries(breakpoints).filter(_ref => {
    let [k] = _ref;
    return isNaN(parseInt(k));
  });
  return entries.reduce((_ref2, _ref3) => {
    let [result, sizes] = _ref2;
    let [breakpoint, value] = _ref3;
    if (!sizes[breakpoint]) {
      return [result, sizes];
    }
    const {
        [breakpoint]: size
      } = sizes,
      rest = _objectWithoutProperties(sizes, [breakpoint].map(_toPropertyKey));
    if (size === undefined) {
      return [result, sizes];
    }
    const width = typeof size === 'string' ? size : "".concat(size, "px");
    if (Object.keys(rest).length === 0) {
      return [result.concat(width), rest];
    }
    return [result.concat("(max-width: calc(".concat(value, " - 1px)) ").concat(width)), rest];
  }, [[], sizes])[0].join(', ');
}