import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { zip, max } from 'lodash';
export default function limitResults(collections, overallLimit) {
  var _max;
  const nonEmpty = collections.filter(collection => collection.items.length > 0);
  if (nonEmpty.length === 0) {
    return [];
  }
  let total = 0;
  const limited = nonEmpty.map(collection => _objectSpread(_objectSpread({}, collection), {}, {
    items: []
  }));
  const maxCollectionLength = (_max = max(nonEmpty.map(collection => collection.items.length))) !== null && _max !== void 0 ? _max : 0;
  for (let collectionIndex = 0; collectionIndex < maxCollectionLength; collectionIndex++) {
    for (const [unfilteredCollection, filteredCollection] of zip(nonEmpty, limited)) {
      // Adding the first element of a collection counts for two
      // because we count the heading of a section as a row
      const cost = collectionIndex === 0 ? 2 : 1;
      if (total + cost > overallLimit) {
        return limited;
      }
      const item = unfilteredCollection === null || unfilteredCollection === void 0 ? void 0 : unfilteredCollection.items[collectionIndex];
      if (item) {
        filteredCollection === null || filteredCollection === void 0 || filteredCollection.items.push(item);
        total += cost;
      }
    }
  }
  return limited;
}