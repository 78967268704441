import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import analyticsUtils from './index.js';
export default function trackClickEvent(eventCategory, eventAction, eventLabel, metadata) {
  return trackGeneralEvent(analyticsUtils.events.USER_CLICK, eventCategory, eventAction, eventLabel, metadata);
}
export function trackGeneralEvent(eventType, eventCategory, eventAction, eventLabel, metadata) {
  // Only send fields which contain values
  return analyticsUtils.DEPRECATED_gaTagTracking(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
    event: eventType
  }, eventCategory && {
    eventCategory
  }), eventAction && {
    eventAction
  }), eventLabel && {
    eventLabel
  }), metadata));
}
export const trackEventWithSelector = function (tracker) {
  let selector = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'a,button';
  return e => {
    if ((e === null || e === void 0 ? void 0 : e.target) instanceof Element) {
      const desiredTarget = e.target.closest(selector);
      if (e.currentTarget.contains(desiredTarget)) {
        var _e$target$textContent, _e$target;
        tracker((_e$target$textContent = e === null || e === void 0 || (_e$target = e.target) === null || _e$target === void 0 ? void 0 : _e$target.textContent) !== null && _e$target$textContent !== void 0 ? _e$target$textContent : '');
      }
    }
  };
};